import { Delete, Launch, QuizOutlined, Settings } from '@mui/icons-material'
import {
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import MuiTable, { TableRow, customCell } from 'Component/MuiTable'
import React, { useEffect, useState } from 'react'

import AIIcon from 'Component/Icons/AIIcon'
import DeleteModal from './DeleteModal'
import { FORMATIONS } from '../../Service/Functions'
import { SHEET_LIST } from 'helpers/graphql/query/sheet/index.graphql'
import ValidateButton from 'Component/ValidateButton'
import { format } from 'date-fns'
import styles from './style.module.scss'
import { useQuery } from '@apollo/client'

const SheetsList = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [currentDelete, setCurrentDelete] = useState(null as null | number)
  const [toValidate, setToValidate] = useState(true)

  const { data, refetch } = useQuery(SHEET_LIST, {
    variables: { page, toValidate },
    fetchPolicy: 'no-cache',
  })
  const navigate = useNavigate()

  useEffect(() => {
    refetch({
      page,
      search: search || undefined,
      toValidate,
    })
  }, [page, search, toValidate])

  const headers: TableRow = [
    customCell('#Id'),
    customCell('Titre'),
    customCell('Utilisateur'),
    customCell('Cycle', 'right'),
    customCell('Étude', 'right'),
    customCell('Année', 'right'),
    customCell('Sujet', 'right'),
    customCell('Date', 'right'),
    '',
  ]

  const rows: TableRow[] =
    data?.AdminSheets.sheets.map(sheet => [
      sheet.id,
      customCell(
        <Typography>
          {sheet.mcq > 0 && (
            <Tooltip title={`QCM avec ${sheet.mcq} questions`}>
              <QuizOutlined color="primary" sx={{ mr: 1 }} />
            </Tooltip>
          )}
          {sheet.withAI && <AIIcon className={styles.aiIcon} />}
          {sheet.title}
        </Typography>,
      ),
      customCell(<Link to={`/users/${sheet.userToken}`}>{sheet.user}</Link>),
      customCell(sheet.cycle ? FORMATIONS[sheet.cycle].name : '-', 'right'),
      customCell(
        sheet.cycle === 1 && sheet.study
          ? FORMATIONS[sheet.cycle].value[sheet.study].name
          : sheet.study || '-',
        'right',
      ),
      customCell(sheet.year || '-', 'right'),
      customCell(sheet.subject || '-', 'right'),
      customCell(format(new Date(sheet.date), 'dd/MM/yyyy'), 'right'),
      customCell(
        <div className={styles.buttons}>
          <div>
            <a href={`/sheet/${sheet.id}`} target="_blank">
              <IconButton>
                <Launch />
              </IconButton>
            </a>
            <IconButton onClick={() => navigate(`/edit/${sheet.id}`)}>
              <Settings />
            </IconButton>
            <IconButton
              onClick={() => setCurrentDelete(sheet.id)}
              color="error"
            >
              <Delete />
            </IconButton>
          </div>
          <div>
            <ValidateButton
              id={sheet.id}
              isValid={sheet.isValid}
              refetch={() => refetch({ page })}
            />
          </div>
        </div>,
      ),
    ]) || []

  return (
    <>
      <DeleteModal
        current={currentDelete}
        onClose={() => setCurrentDelete(null)}
        refetch={() => refetch({ page })}
      />
      <div className={styles.filter}>
        <FormControlLabel
          control={
            <Switch
              checked={toValidate}
              onChange={(_, c) => setToValidate(c)}
            />
          }
          label="Fiches à valider uniquement"
        />
      </div>
      <MuiTable
        headers={headers}
        rows={rows}
        onSearch={v => {
          setSearch(v)
        }}
        updatePage={page => {
          setPage(page)
        }}
        total={data?.AdminSheets.count}
      />
    </>
  )
}

export default SheetsList
