import { Button } from '@mui/material'
import { GET_HTML } from 'helpers/graphql/query/sheet/index.graphql'
import { Query } from 'helpers/utils/__generated__/resolvers-types'
import React from 'react'
import VisualizeHtml from '../../Component/Visualizers/VisualizeHtml'
import styles from './style.module.scss'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

const Sheet = () => {
  const { id } = useParams()

  if (!id) return

  const { data: sheet } = useQuery<Query>(GET_HTML, {
    variables: { sheet: +id },
  })

  if (!sheet) return

  return (
    <div className={styles.container}>
      <VisualizeHtml
        html={
          sheet.GeneratedSheet.type === 0
            ? sheet.GeneratedSheet.value
            : undefined
        }
        file={
          sheet.GeneratedSheet.type === 1
            ? sheet.GeneratedSheet.value
            : undefined
        }
        type={sheet.GeneratedSheet.type}
        template="classic"
      />
      <div className={styles.download}>
        <Button
          variant="outlined"
          size="small"
          onClick={async () => {
            if (window) {
              window.print()
            }
          }}
        >
          Télécharger au format pdf
        </Button>
      </div>
    </div>
  )
}

export default Sheet
