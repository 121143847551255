import React, {Component, createRef, useCallback, useEffect, useState} from "react";
import Quill from "quill"
import "quill/dist/quill.snow.css"
import {IconButton} from "@mui/material";
import {HighlightOff} from "@mui/icons-material";


export default function Title(props) {
    const [quill, setQuill] = useState()
    let toolbar_options = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }]
    ]

    useEffect(() => {
        if (quill == null) return

        const handler = (delta, oldDelta, source) => {
            props.onChange(quill.root.innerHTML)
        }
        quill.on("text-change", handler)

        return () => {
            quill.off("text-change", handler)
        }
    }, [quill])


    const wrapperRef = useCallback(wrapper => {
        if (typeof document === "undefined") return;
        if (wrapper == null) return

        wrapper.innerHTML = ""
        const editor = document.createElement("div")
        wrapper.append(editor)
        const q = new Quill(editor, {
            theme: "snow",
            placeholder: 'Titre',
            modules: {toolbar: toolbar_options},
        })
        setQuill(q)
    }, [])

    return <>
        <div className="row mt-3">
            <div className="col-1 delete-button">
                <IconButton aria-label="delete" onClick={props.onDelete}>
                    <HighlightOff />
                </IconButton>
            </div>

            <div className={"col-11"} ref={wrapperRef}/>
        </div>
    </>

}