import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'

import AdminList from './Views/Admin'
import Analytics from './Views/Analytics'
import Blog from './Views/Blog'
import Dashboard from './Views/Dashboard'
import { Definitions } from './Views/Definitions/Definitions'
import Editor from './Views/Blog/Editor'
import Email from './Views/Email'
import Generator from './Views/Sheets/Generator'
import Login from './Views/Login'
import React from 'react'
import Sheet from './Views/Sheet'
import SheetsList from './Views/Sheets'
import Users from './Views/Users'
import { connect } from 'react-redux'
import { httpLink } from './helpers/graphql/apollo-client'
import { setContext } from '@apollo/client/link/context'

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action)
    },
  }
}

function App(props) {
  const PrivateRoute = ({ children }) => {
    if (!props.authReducer.loggedIn) return <Navigate to="/" />
    return children
  }

  const IsNotConnectedRoute = ({ children }) => {
    if (props.authReducer.loggedIn) return <Navigate to="/dashboard" />
    return children
  }

  const AdminRoute = ({ children }) => {
    if (!props.authReducer.loggedIn) return <Navigate to="/" />
    if (props.authReducer.level < 3) return <Navigate to="/dashboard" />
    return children
  }

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: props.authReducer.token
          ? `${props.authReducer.token}`
          : '',
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  })

  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <IsNotConnectedRoute>
                <Login />
              </IsNotConnectedRoute>
            }
          />
          <Route
            path="/sheets"
            element={
              <PrivateRoute>
                <SheetsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/sheet/:id"
            element={
              <PrivateRoute>
                <Sheet />
              </PrivateRoute>
            }
          />
          <Route
            path="/blog"
            element={
              <PrivateRoute>
                <Blog />
              </PrivateRoute>
            }
          />
          <Route
            path="/blog/new"
            element={
              <PrivateRoute>
                <Editor />
              </PrivateRoute>
            }
          />
          <Route
            path="/blog/:id"
            element={
              <PrivateRoute>
                <Editor />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:token"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit/:token"
            element={
              <PrivateRoute>
                <Generator />
              </PrivateRoute>
            }
          />
          <Route
            path="/definitions"
            element={
              <PrivateRoute>
                <Definitions />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <AdminList />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/email"
            element={
              <AdminRoute>
                <Email />
              </AdminRoute>
            }
          />
          <Route
            path="/analytics"
            element={
              <AdminRoute>
                <Analytics />
              </AdminRoute>
            }
          />
        </Routes>
      </Router>
    </ApolloProvider>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
