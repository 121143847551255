import 'quill/dist/quill.snow.css'

import { FormControlLabel, FormGroup, Switch } from '@material-ui/core'
import {
  Mutation,
  SendEmailInput,
} from 'helpers/utils/__generated__/resolvers-types'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import { Modal } from 'react-bootstrap'
import Quill from 'quill'
import { SEND_EMAIL } from 'helpers/graphql/mutation/email.graphql'
import TextField from '@material-ui/core/TextField'
import { checkEmailAddr } from '../Service/Functions'
import { useMutation } from '@apollo/client'

export default function Email() {
  const [quill, setQuill] = useState<Quill>()
  const [email, setEmail] = useState('')
  const [sendModal, setSendModal] = useState(false)
  const [title, setTitle] = useState('')
  const [list, setList] = useState([] as string[])
  const [emailSwitch, setEmailSwitch] = useState(false)

  const [send, { data, loading }] = useMutation<Mutation>(SEND_EMAIL)

  useEffect(() => {
    if (quill == null) return

    const handler = (delta, oldDelta, source) => {
      setEmail(quill.root.innerHTML)
    }
    quill.on('text-change', handler)

    return () => {
      quill.off('text-change', handler)
    }
  }, [quill])

  const wrapperRef = useCallback(wrapper => {
    if (typeof document === 'undefined') return
    if (wrapper == null) return

    wrapper.innerHTML = ''
    const editor = document.createElement('div')
    wrapper.append(editor)
    const q = new Quill(editor, {
      theme: 'snow',
      placeholder: '',
      modules: {
        toolbar: [
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['bold', 'italic', 'underline'],
          [{ color: [] }, { background: [] }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ align: [] }],
          ['image', 'blockquote', 'code-block'],
          ['clean'],
        ],
      },
    })
    setQuill(q)
  }, [])

  useEffect(() => {
    if (data?.SendEmail) {
      setSendModal(false)
      setTitle('')
      setList([])
      quill.root.innerHTML = null
      alert('Email envoyé !')
    }
  }, [data?.SendEmail])

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 my-2">
          <p className={'mb-1 fw-bold'}>Sujet</p>
          <TextField
            variant="outlined"
            size={'small'}
            className={'w-100'}
            value={title}
            onChange={e => {
              setTitle(e.target.value)
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <p className={'mb-0 fw-bold'}>Email</p>
        </div>
        <div className={'col-12'} ref={wrapperRef} />
        <div className="col-12 text-center mt-3">
          <Button
            variant={'contained'}
            color={'primary'}
            size={'small'}
            onClick={() => {
              setSendModal(true)
            }}
          >
            Envoyer
          </Button>
        </div>
        <div className="col-12 mt-2">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={emailSwitch}
                  onChange={e => {
                    setEmailSwitch(e.target.checked)
                  }}
                  color={'primary'}
                />
              }
              label="Envoyer à tous les utilisateurs."
            />
          </FormGroup>
        </div>
        {!emailSwitch ? (
          <>
            <div className="col-12">
              <Autocomplete
                className={'w-100'}
                multiple
                value={list}
                onChange={(e, n) => {
                  if (!n.length || checkEmailAddr(n[n.length - 1])) {
                    setList(n)
                  }
                }}
                options={[] as string[]}
                filterSelectedOptions
                freeSolo
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Adresses emails"
                    placeholder="Adresse email"
                  />
                )}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <Modal
        show={sendModal}
        onHide={() => {
          setSendModal(false)
        }}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Envoyer l'email ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 text-center">
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => {
                  const variables: SendEmailInput = {
                    all: emailSwitch,
                    content: quill.root.innerHTML,
                    emails: list,
                    subject: title,
                  }
                  send({
                    variables: {
                      data: variables,
                    },
                  })
                }}
                disabled={loading}
              >
                {loading ? 'Envoie en cours' : 'Envoyer'}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
