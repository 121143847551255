import {CloudUpload, HighlightOff} from "@mui/icons-material";
import {IconButton, TextField} from "@mui/material";
import React, {Component, createRef} from "react";

import {checkIfB64} from "../Service/Functions";

export class Image extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.hiddenFileInput = createRef();
    }

    getBase64 = (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    buildFileSelector = () => {
        this.hiddenFileInput.current.click();
    }

    uploadImage = (e) => {
        let file = e.target.files[0];

        if (file['name'] && RegExp(/.+\.(jpg|png|jpeg)$/gi).test(file['name'])) {
            this.getBase64(file)
                .then(result => {
                    let img = this.props.value
                    img["content"] = result
                    this.props.onChange(img)
                })
        }
    }

    onChange = (e) => {
        let img = this.props.value
        img[e.target.name] = e.target.value
        this.props.onChange(img)
    }

    render() {
        return <>
            <input type="file"
                   accept="image/*"
                   ref={this.hiddenFileInput}
                   onChange={this.uploadImage}
                   style={{display: 'none'}}/>

            <div className="row mt-3">
                <div className="col-1 delete-button">
                    <IconButton aria-label="delete" onClick={this.props.onDelete}>
                        <HighlightOff />
                    </IconButton>
                </div>
                <div className={"col-11 col-md-3 px-1"}>
                    <TextField placeholder="Légende" variant="outlined" className={"w-100"}
                               value={this.props.value.title} name={"title"} onChange={this.onChange}/>
                </div>
                {
                    !this.props.value.content
                    ? <div className={"col-12 col-md-8 px-0 mt-1 mt-md-0 input-file"}>
                        <span>Image ou schéma</span>
                            <IconButton onClick={this.buildFileSelector}>
                                <CloudUpload/>
                            </IconButton>
                        </div>
                        : <div className={"col-12 col-md-8 px-1 mt-1 mt-md-0"}>
                            <img className={"img-generator"} src={checkIfB64(this.props.value.content) ? this.props.value.content : `${process.env.REACT_APP_S3}sheetsImages/${this.props.value.content}`} onClick={this.buildFileSelector}/>
                        </div>
                }
            </div>

        </>
    }
}