import 'quill/dist/quill.snow.css'

import React, { useCallback, useEffect, useState } from 'react'

import Quill from 'quill'

type EditorProps = {
  onChange: (value: string) => void
  value: string
}

export default function Editor(props: EditorProps) {
  const { value, onChange } = props
  const [quill, setQuill] = useState<Quill>()

  const toolbar_options = [
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    ['image', 'blockquote', 'code-block'],
    [{ indent: '-1' }, { indent: '+1' }],
    ['clean'],
  ]

  useEffect(() => {
    if (quill == null) return
    if (quill.root.innerHTML === '<p><br></p>') {
      const delta = quill.clipboard.convert(value)
      quill.setContents(delta, 'silent')
    }
  }, [value, quill])

  useEffect(() => {
    if (quill == null) return

    const handler = (delta, oldDelta, source) => {
      onChange(quill.root.innerHTML)
    }
    quill.on('text-change', handler)
    quill.on('selection-change', function (range, oldRange, source) {
      if (range === null && oldRange !== null) {
      } else if (range !== null && oldRange === null) console.log('focus')
    })

    return () => {
      quill.off('text-change', handler)
    }
  }, [quill, onChange])

  const wrapperRef = useCallback(wrapper => {
    if (typeof document === 'undefined') return
    if (wrapper == null) return

    wrapper.innerHTML = ''
    const editor = document.createElement('div')
    wrapper.append(editor)
    const q = new Quill(editor, {
      theme: 'snow',
      placeholder: '',
      modules: { toolbar: toolbar_options },
    })
    setQuill(q)
  }, [])

  return (
    <>
      <div ref={wrapperRef}></div>
    </>
  )
}
