import { gql } from 'helpers/utils/__generated__'

export const GET_HTML = gql(/* GraphQL */ `
  query GeneratedSheet($sheet: Int!) {
    GeneratedSheet(sheet: $sheet) {
      value
      type
      title
      study
      cycle
      year
      hasAccess
      subject
    }
  }
`)

export const SHEET_LIST = gql(/* GraphQL */ `
  query AdminSheets($page: Int, $search: String, $toValidate: Boolean) {
    AdminSheets(page: $page, search: $search, toValidate: $toValidate) {
      count
      sheets {
        id
        title
        cycle
        subject
        user
        year
        type
        review
        study
        date
        isValid
        userToken
        withAI
        mcq
      }
    }
  }
`)
