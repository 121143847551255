import MuiTable, { TableRow } from 'Component/MuiTable'

import { ADMIN_LIST } from 'helpers/graphql/query/user/index.graphql'
import { Query } from 'helpers/utils/__generated__/resolvers-types'
import React from 'react'
import { Typography } from '@mui/material'
import { useQuery } from '@apollo/client'

const Admin = () => {
  const { data } = useQuery<Query>(ADMIN_LIST)

  const headers: TableRow = ['#ID', 'Aministrateur']

  const rows: TableRow[] =
    data?.AdminList.map(admin => [admin.id, admin.email]) || []

  return (
    <>
      <Typography variant="h1">Gestion administrateur</Typography>
      <MuiTable rows={rows} headers={headers} />
    </>
  )
}

export default Admin
