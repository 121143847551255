export const firstLetterUppercase = str => {
  return str[0].toUpperCase() + str.slice(1)
}

export const checkEmailAddr = email => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )
}

export const getInitial = (firstName, lastName) => {
  return firstName[0] + lastName[0]
}

export const toUTC = date => {
  date = new Date(date)
  return `${date.getFullYear()}-${
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
}

export const FORMATIONS = [
  {
    name: 'Collège',
    value: ['Sixième', 'Cinquième', 'Quatrième', 'Troisième'],
  },
  {
    name: 'Lycée',
    value: [
      {
        name: 'Bac général et technologique',
        value: ['Seconde', 'Première', 'Terminale'],
        speciality: [
          'Histoire géographie, géopolitique et sciences politiques',
          'Humanités, littérature et philosophie',
          'Langues, littératures et cultures étrangères',
          'Mathématiques',
          'Physique-chimie',
          'Sciences de la vie et de la Terre',
          'Sciences économiques et sociales',
          'Arts : arts plastiques ou cinéma-audiovisuel ou danse ou histoire des arts ou musique ou théâtre',
          'Biologie écologie, dans les lycées agricoles uniquement',
          'Littérature, langues et cultures de l’Antiquité',
          'Numérique et sciences informatiques',
          "Sciences de l'ingénieur",
        ],
      },
      {
        name: 'Bac pro',
        value: ['Seconde', 'Première', 'Terminale'],
        speciality: [
          'Logistique',
          'Hygiène et environnement',
          'Vente',
          'Accueil - relation clients et usagers',
          'Commerce',
          'Commerce international',
          'Gestion',
          'Administration des entreprises',
          'Secrétariat',
          'Aéronautique',
          'Aviation générale',
          'Agroéquipement',
          'Technicien aérostructures',
          'Cultures marines',
          'Conduite et gestion de l’exploitation agricole',
          'Productions aquacoles',
          'Ouvrage du bâtiment',
          'importruction des carrosseries',
          'Bio industrie transformation',
          'Métiers de l’alimentation',
          "Métiers de l'accueil",
          'Commerce',
          'Maintenance de véhicules automobiles',
          'Esthétique cosmétique parfumerie',
          'Travaux publics',
          'Aménagement et finition du bâtiment',
          'Photographie',
          'Électrotechnique, énergie, équipements communicants',
          'Intervention sur le patrimoine bâti',
          'Microtechnique',
          'Technicien d’études du bâtiment',
          'Technicien géomètre topographe',
          'Hygiène, Propreté et Stérilisation',
          'Micro-informatique et réseaux',
          'Vente, prospection, négociation, suivi de clientèle',
          'Production graphique',
          'Industrie de procédés',
          'Perruquier Posticheur',
          'Réalisation de produits imprimés et plurimédia',
          'Systèmes électroniques numériques',
          'Gestion des Pollutions et Protection de l’Environnement',
          'Environnement nucléaire',
          'Hygiène et Environnement',
          'Étude et Définition de Produits Industriels',
          'Industries des pates, papiers et cartons',
          'Industrie de transformation',
          'Fonderie',
          'Maintenance des équipements industriels',
          'Conducteur Transport Routier Marchandises (CTRM)',
          'Exploitation des transports',
          'Logistique',
          'Transport',
          'Technicien de maintenance',
          'Sécurité prévention',
          'Technicien menuisier-agenceur',
          'Systèmes Numériques (SN)',
          'Usinage',
          'Maintenance de véhicules',
          'Technicien en chaudronnerie industrielle',
          'Électrotechnique, énergie, équipement',
          'Microtechnique',
          'Maintenance des équipements industriels',
          'Maintenance qualité',
          'Étude et définition de produits industriels',
          'Productique mécanique',
          'Artisanat et métiers d’art',
          'Technicien menuisier agenceur',
          'Métiers de la mode',
          'Métiers du cuir option Maroquinerie',
          'Métiers du pressing et de la blanchisserie',
          'Sécurité Prévention',
          'Métiers de la sécurité',
          'ARCU (accueil relation clients et usagers)',
          'ASSP (Accompagnement, Soins et Services à la Personne)',
          'Gestion-Administration',
          'Services de Proximité et Vie Locale',
          'Cuisine',
          'Restauration',
          'Poissonnier écailler traiteur',
          'Boucher charcutier traiteur',
          'Boulanger - pâtissier',
          'Commercialisation et Services en Restauration',
        ],
      },
    ],
  },
  {
    name: 'Post-Bac',
    value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
]

export const slugify = str => {
  const map = {
    '-': ' |_',
    a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    e: 'é|è|ê|ë|É|È|Ê|Ë',
    i: 'í|ì|î|ï|Í|Ì|Î|Ï',
    o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  }

  for (const pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern)
  }

  return str.toLowerCase()
}

export const b64toImage = base => {
  const block = base.split(';')
  const contentType = block[0].split(':')[1]
  const b64Data = block[1].split(',')[1]

  const sliceSize = 512

  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export const checkIfB64 = file => {
  const pattern = /^data:image\/.+;base64/
  return pattern.test(file)
}

export const SUBJECTS = [
  'Anthropologie',
  'Anthropologie sociale et culturelle',
  'Archéologie',
  'Biologie',
  'Agronomie',
  'Anatomie',
  'Anthropologie',
  'Astrobiologie',
  'Bio-informatique',
  'Biochimie',
  'Biologie cellulaire',
  "Biologie d'évolution",
  "Biologie d'eau douce",
  'Biologie du développement',
  'Biologie marine',
  'Biologie moléculaire',
  'Biologie structurale',
  'Biomathématique',
  'Biophysique',
  'Botanique',
  'Cryobiologie',
  'Cytologie',
  'Ecologie',
  'Epidémiologie',
  'Génétique',
  'Histologie',
  'Microbiologie',
  'Morphologie',
  'Mycologie',
  'Neurosciences',
  'Ontogénie',
  'Paléontologie',
  'Protéomique',
  'Sciences de la santé',
  'Systématique',
  'Zoologie',
  'Chimie',
  'Biochimie',
  'Chimie analytique',
  'Chimie des matériaux',
  'Chimie inorganique',
  'Chimie numérique',
  'Chimie organique',
  'Physique Chimie',
  'Chimie quantique',
  'Chimie supramoléculaire',
  'Chimie théorique',
  'Électrochimie',
  'Génie des procédés',
  'Géochimie',
  'Minéralogie',
  'Pétrochimie',
  'Spectroscopie',
  'Thermochimie',
  'Criminologie',
  'Biocriminologie',
  'Criminologie juvénile',
  'Criminologie sociologique',
  'Médecine légale',
  'Pénologie',
  'Polémologie',
  'Psychocriminologie',
  'Victimologie',
  'Economie',
  'Analyse économique du droit',
  'Econométrie',
  'Economie comportementale',
  "Économie de l'assurance",
  "Économie de l'environnement",
  'Economie de la culture',
  'Économie de la santé',
  'Économie des conventions',
  'Économie des institutions',
  'Économie des territoires',
  'Économie du bien-être',
  'Économie du développement',
  'Économie du sport',
  'Économie du travail',
  'Économie expérimentale',
  'Économie financière',
  'Économie internationale',
  'Économie mathématique et quantitative',
  'Économie monétaire',
  'Économie politique',
  'Économie publique',
  'Économie régionale',
  'Économie sociale',
  'Économie urbaine',
  'Macroéconomie',
  'Microéconomie',
  'Théorie des organisations',
  'Géographie',
  'Analyse spatiale',
  'Démographie',
  'Géoarchéologie',
  'Géographie humaine',
  'Géographie régionale',
  'Géomatique',
  'Géopolitique',
  'Histoire',
  'Histoire économique',
  'Histoire culturelle',
  "Histoire de l'art",
  'Histoire des idées politiques',
  'Histoire politique contemporaine',
  'Histoire sociale',
  'Micro-histoire',
  'Muséologie',
  'Numismatique',
  'Philologie',
  'Informatique théorique et Mathématiques discrètes',
  'Algorithmique',
  'Cryptologie',
  'Méthode formelles',
  'Recherche opérationnelle',
  "Théorie de l'information",
  'Théorie des graphes',
  'Théorie des langages',
  "Théorie des systèmes d'informations",
  'Théories du calcul',
  'Traitement du signal',
  'Linguistique',
  'Analyse conversationnelle',
  'Analyse linguistique de textes',
  'Dialectologie',
  'Lexicographie',
  'Lexicologie',
  'Linguistique fonctionnelle',
  'Linguistique informatique',
  'Linguistique structurale',
  'Phonétique',
  'Phonologie',
  'Sociolinguistique',
  "Théorie de l'apprentissage des langues",
  'Logique',
  'Logique mathématique',
  'Philosophie analytique',
  'Théorie des catégories',
  'Mathématiques',
  'Algèbre',
  'Analyse',
  'Géométrie',
  'Théorie des probabilités et Statistiques',
  'Musicologie',
  'Ethnomusicologie',
  'Organologie',
  'Sociologie de la musique',
  'Physicochime',
  'Science des matériaux',
  'Physique',
  'Astronomie',
  'Cryogénie',
  'Electromagnétisme',
  'Mécanique',
  'Optique',
  'Optronique',
  'Physique de la matière condensée',
  'Physique des plasmas',
  'Physique mathématique',
  'Physique quantique',
  'Physique théorique',
  "Sciences pour l'ingénieur",
  'Thermodynamique',
  'Psychologie',
  'Neuropsychologie',
  'Psychanalyse',
  'Psychobiologie',
  'Psychogénétique',
  'Psycholinguistique',
  'Psycholinguistique',
  'Psychologie évolutionniste',
  'Psychologie clinique',
  'Psychologie collective',
  'Psychologie communautaire',
  "Psychologie de l'adulte au travail",
  "Psychologie de l'emploi",
  'Psychologie de la famille',
  'Psychologie de la motivation',
  'Psychologie de la perception',
  'Psychologie de la réadaptation',
  'Psychologie de la religion',
  'Psychologie de la santé',
  'Psychologie de la sexualité',
  'Psychologie du développement',
  'Psychologie du travail',
  'Psychologie environnementale',
  'Psychologie expérimentale',
  'Psychologie gérontologique',
  'Psychologie sociale',
  'Psychomécanique du langage',
  'Psychométrie',
  'Psychopédagogie',
  'Psychopathologie',
  'Psychophonie',
  'Psychophysiologie',
  'Psychosociologie',
  'Sciences cognitives',
  'Sociologie',
  'Science de la conception',
  'Musicologie',
  'Sciences architecturales',
  'Sciences du design',
  "Sciences pour l'ingénieur",
  "Sciences de l'éducation",
  'Didactique',
  'Pédagogie',
  'Sciences de la religion',
  'Théologie',
  'Sciences de la terre ou géosciences',
  'Géographie',
  'Géologie',
  'Géophysique',
  'Granulométrie',
  'Minéralogie',
  'Nanogéosciences',
  'Pétrographie',
  'Pétrologie',
  'Paléontologie',
  'Spéléologie',
  'Volcanologie',
  'Sciences juridiques',
  'Droit comparé',
  'Droit international',
  'Droit interne',
  'Histoire du droit',
  'Philosophie du droit',
  'Sociologie du droit',
  'Théorie du droit',
  'Sciences médicales et paramédicales',
  'Médecine',
]

export const formatDate = date => {
  if (date && date !== '0000-00-00') {
    date = new Date(date)
    return `${date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`}-${
      date.getMonth() + 1 >= 10
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`
    }-${date.getFullYear()}`
  }
  return null
}

export const formatDateHour = dateToFormat => {
  const date = new Date(dateToFormat)
  return `${formatDate(dateToFormat)} ${date.getHours()}:${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  }`
}

export const formatHour = dateToFormat => {
  const date = new Date(dateToFormat)
  return `${date.getHours()}:${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  }`
}

export const secToMin = value => {
  return `${Math.floor(value / 60)} min ${value % 60 ? value % 60 : '00'} s`
}
