import React, {Component, useEffect, useState} from "react";
import Button from "@material-ui/core/Button"
import Definition from "../../Component/Definition";
import {Image} from "../../Component/Image";
import Editor from "../../Component/Editor";
import Title from "../../Component/Title";
import {connect} from 'react-redux'
import {AddCircleOutline, ExpandLess} from "@mui/icons-material";
import {Accordion, Modal} from "react-bootstrap";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import parse from 'html-react-parser';
import {get, post} from "../../Service/Requests";
import {Alert, Snackbar, FormControl, MenuItem, Select, TextField, Typography, IconButton} from "@mui/material";
import {b64toImage, checkIfB64, slugify, FORMATIONS, SUBJECTS} from "../../Service/Functions";

const filter = createFilterOptions();

const DEFAULT_EDITOR_TEMPLATE = [
    {
        type: 0,
        title: "Définitions",
        button: "Ajouter une définition",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        max: 5
    },
    {
        type: 2,
        title: "Image ou schéma",
        button: "Ajouter une image",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        max: 1
    },
    {
        type: 1,
        title: "Texte explicatif",
        button: "Ajouter un texte",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        max: 1
    }
]

const INPUTS = [
    {
        name: "Définition",
        type: 0
    }, {
        name: "Texte",
        type: 1
    }, {
        name: "Image",
        type: 2
    }, {
        name: "Titre",
        type: 3
    }
]


const mapStateToProps = (state) => {
    return state
}

class Generator extends Component {
    constructor(props) {
        super();
        let datas = {
            title: null,
            study: null,
            cycle: null,
            year: null,
            speciality: null,
            subject: null,
        }
        this.state = {
            editor: [],
            premium: true,
            newModal: false,
            visualize: false,
            addTo: null,
            datas: datas,
            error: false,
            success: false,
        }
        this.addAutocompleteRef = React.createRef()
    }

    componentDidMount() {
        get("admin/sheet/modify/" + this.props.match.params.token)
            .then((res) => {
                this.setState({
                    datas: res.data.sheet.datas,
                    editor: res.data.sheet.editor,
                    premium: res.data.sheet.premium,
                    user: res.data.sheet.user,
                })
            })
    }

    add = (type = 0, index = -1) => {
        let item = {}
        if (type === 1 || type === 3) {
            item = {
                type: type,
                value: ""
            }
        } else {
            item = {
                type: type,
                value: {
                    title: null,
                    content: ""
                }
            }
        }
        let edi = this.state.editor
        edi.splice(index, 0, item);
        this.setState({
            editor: edi
        })
    }

    onChangeAutocomplete = (e, v) => {
        let datas = this.state.datas
        if (e === "speciality") {
            if (datas.study === 0) {
                v = v.splice(0, this.state.datas.year === 1 ? 3 : 2)
            }
        }
        datas[e] = v

        console.log(datas)
        this.setState({
            datas: datas
        })
    }

    onChangeDatas = (e) => {
        let datas = this.state.datas
        let n = e.target.name
        datas[n] = e.target.value
        if (n === "year") {
            datas['speciality'] = []
        }

        this.setState({
            datas: datas
        })
    }

    onChangeEditor = (v, i) => {
        let edi = this.state.editor
        edi[i]['value'] = v
        this.setState({
            editor: edi
        })
    }

    onDeleteEditor = (i) => {
        console.log(i)
        let edi = this.state.editor
        edi.splice(i, 1)
        this.setState({
            editor: edi
        })
    }

    onToggle = (type) => {
        document.getElementById("generator-button" + type).classList.toggle("open")
    }

    resume = async () => {
        let images = this.state.editor.filter(e => e.type === 2 && checkIfB64(e.value.content))
        for (let j = 0; j < images.length; j++) {
            let i = images[j]
            let datas = new FormData()
            datas.set("file", b64toImage(i.value.content))
            await new Promise(resolve => {
                post("admin/image", datas)
                    .then(res => {
                        let editor = this.state.editor
                        editor[editor.findIndex((e) => e === i)].value.content = res.data.file
                        this.setState({
                            editor: editor
                        }, () => {
                            resolve()
                        })
                    })
            })
        }

        post('admin/sheet/', {
            datas: JSON.stringify(this.state.datas),
            editor: JSON.stringify(this.state.editor),
            sheetToken: this.props.match.params.token,
            user: this.state.user
        })
            .then(() => {
                this.setState({
                    success: true,
                    visualize: false
                })            })
            .catch(() => {
                this.setState({
                    error: true
                })
            })
    }

    save = () => {
        let datas = this.state.datas
        if (datas.title === null || datas.cycle === null || datas.year === null ||
            datas.subject === null || (datas.cycle !== 0 && datas.speciality === []) || (datas.cycle !== 0 && datas.study === null)) {
            this.setState({
                newModal: true
            })
        } else {
            this.setState({
                visualize: true
            })
        }
    }


    render() {

        let current = 0
        return <>
            <div className={"container w-md-70 px-2"}>
                {
                    !this.state.premium
                        ? <>
                            {
                                DEFAULT_EDITOR_TEMPLATE.map((t) => {
                                    return <>
                                        <Accordion defaultActiveKey="0" className={" mb-2 "}>
                                            <p className="generator-title">
                                                <Typography variant={'h1'}>{t.title}
                                                    <Accordion.Toggle as={"div"} variant="link" eventKey="0">
                                                        <IconButton aria-label="delete" className={"open"}
                                                                    id={"generator-button" + t.type}
                                                                    onClick={() => {
                                                                        this.onToggle(t.type)
                                                                    }}>
                                                            <ExpandLess/>
                                                        </IconButton>
                                                    </Accordion.Toggle>
                                                </Typography>
                                            </p>
                                            <Accordion.Collapse eventKey="0" className={"generator-div open"}>
                                                <div>
                                                    <div>
                                                        {
                                                            this.state.editor.map((e, i) => {
                                                                if (t.type === 0 && e.type === t.type) return <Definition
                                                                    value={e.value}
                                                                    onChange={(v) => this.onChangeEditor(v, i)}
                                                                    onDelete={() => this.onDeleteEditor(i)}/>
                                                                if (e.type === 2 && e.type === t.type) return <Image
                                                                    value={e.value}
                                                                    onChange={(v) => this.onChangeEditor(v, i)}
                                                                    onDelete={() => this.onDeleteEditor(i)}/>
                                                                if (e.type === 1 && e.type === t.type) return <Editor
                                                                    value={e.value}
                                                                    premium={this.state.premium}
                                                                    onChange={(html) => this.onChangeEditor(html, i)}
                                                                    onDelete={() => this.onDeleteEditor(i)}/>
                                                            })
                                                        }
                                                    </div>

                                                    <div className="w-100 text-center my-2">
                                                        <Button
                                                            color={"primary"}
                                                            variant={"contained"}
                                                            onClick={() => this.add(t.type, this.state.editor.filter(e => e.type === t.type).length)}
                                                            disabled={this.state.editor.filter(e => e.type === t.type).length >= t.max}
                                                        >{t.button}</Button>
                                                        {this.state.editor.filter(e => e.type === 0).length >= t.max
                                                            ? <p className="f-15 info">{t.info}</p>
                                                            : null}
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </Accordion>


                                    </>
                                })
                            }


                            {/*<div className={"generator-div"}>*/}
                            {/*    <div>*/}
                            {/*        {*/}
                            {/*            this.state.editor.map((e, i) => {*/}
                            {/*                if(e.type === 0) return <Definition value={e.value} onChange={(v) => this.onChangeEditor(v, i)}/>*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </>
                        : <>
                            {
                                this.state.editor.map((e, i) => {
                                    let input = () => {
                                        if (e.type === 0) return <Definition value={e.value}
                                                                             onChange={(v) => this.onChangeEditor(v, i)}
                                                                             onDelete={() => this.onDeleteEditor(i)}/>
                                        // if (e.type === 'button') return <div className="text-center"><Button color={"primary"}
                                        //                                                                      variant={"contained"}
                                        //                                                                      onClick={() => this.add(e.addType, i)}>{e.value}</Button>
                                        // </div>
                                        if (e.type === 2) return <Image value={e.value}
                                                                        onChange={(v) => this.onChangeEditor(v, i)}
                                                                        onDelete={() => this.onDeleteEditor(i)}/>
                                        if (e.type === 1) return <Editor value={e.value}
                                                                         premium={this.state.premium}
                                                                         onChange={(html) => this.onChangeEditor(html, i)}
                                                                         onDelete={() => this.onDeleteEditor(i)}/>
                                        if (e.type === 3) return <Title value={e.value}
                                                                        onChange={(html) => this.onChangeEditor(html, i)}
                                                                        onDelete={() => this.onDeleteEditor(i)}/>
                                    }
                                    return <>
                                        <div className="text-center add-button-hover">
                                            <IconButton aria-label="add" className={"add-button"} onClick={() => {
                                                this.setState({addTo: i}, () => this.addAutocompleteRef.current.classList.toggle("show"))
                                            }}>
                                                <AddCircleOutline/>
                                            </IconButton>
                                        </div>
                                        {input()}
                                    </>
                                })
                            }

                            <div className="text-center">
                                <IconButton aria-label="add" className={"add-button"} onClick={() => {
                                    this.setState({addTo: this.state.editor.length}, () => this.addAutocompleteRef.current.classList.toggle("show"))
                                }}>
                                    <AddCircleOutline/>
                                </IconButton>
                            </div>
                        </>
                }

                <div className="text-center mt-5">
                    <Button variant={"outlined"} color={"primary"} size={"small"} className={"m-2 mb-4"}
                            onClick={() => {
                                this.setState({newModal: true})
                            }}>
                        Modifier les informations de cette fiche
                    </Button>
                    <Typography variant={"h3"} component={"p"}>Tout semble bon ?</Typography>
                    <Button variant={"contained"} color={"primary"} className={"m-2"} onClick={this.save}>
                        Relire et visualiser la fiche
                    </Button>
                </div>
            </div>

            <div className="add-item" ref={this.addAutocompleteRef}>
                <div onClick={() => this.addAutocompleteRef.current.classList.toggle("show")}/>
                <FormControl variant="outlined">
                    <Select
                        onChange={(e) => {
                            this.add(e.target.value, this.state.addTo)
                            this.addAutocompleteRef.current.classList.toggle("show")
                        }}
                        value={-1}
                        openOnFocus
                    >
                        <MenuItem value={-1} disabled>Type</MenuItem>
                        {
                            INPUTS.map(u => <MenuItem value={u.type}>{u.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </div>

            <Modal
                show={this.state.newModal}
                onHide={() => {
                    this.setState({newModal: false})
                }}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Informations générales</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className={"col-12"}>
                            <p className={"mb-1 fw-bold"}>Titre de la fiche</p>
                            <TextField placeholder="Votre titre" variant="outlined" className={"w-100"}
                                       value={this.state.datas.title}
                                       name={"title"} onChange={this.onChangeDatas}/>
                        </div>
                        <div className="col-6 mt-2">
                            <p className={"mb-1 fw-bold"}>Cycle</p>
                            <FormControl variant="outlined" className={"w-100"}>
                                <Select onChange={this.onChangeDatas} name={"cycle"}
                                        value={this.state.datas.cycle}>
                                    {
                                        FORMATIONS.map((f, i) => {
                                            return <MenuItem value={i}>{f.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        {
                            this.state.datas.cycle
                                ? <div className="col-6 mt-2">
                                    <p className={"mb-1 fw-bold"}>Études</p>
                                    {
                                        this.state.datas.cycle === 1
                                            ? <FormControl variant="outlined" className={"w-100"}>
                                                <Select onChange={this.onChangeDatas} name={"study"}
                                                        value={this.state.datas.study}>
                                                    {
                                                        FORMATIONS[this.state.datas.cycle].value.map((f, i) => {
                                                            return <MenuItem value={i}>{f.name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            : <TextField placeholder="Votre titre" variant="outlined"
                                                         className={"w-100"}
                                                         value={this.state.datas.study}
                                                         name={"study"} onChange={this.onChangeDatas}/>


                                    }
                                </div>
                                : <></>
                        }
                        {
                            this.state.datas.cycle === 0 || this.state.datas.study !== null
                                ? <>
                                    <div className="col-6 mt-2">
                                        <p className={"mb-1 fw-bold"}>Année</p>
                                        <FormControl variant="outlined" className={"w-100"}>
                                            {
                                                this.state.datas.cycle === 0 || this.state.datas.cycle === 2
                                                    ? <>
                                                        <Select onChange={this.onChangeDatas} name={"year"}
                                                                value={this.state.datas.year}>
                                                            {
                                                                FORMATIONS[this.state.datas.cycle].value.map((f, i) => {
                                                                    return <MenuItem value={i}>{f}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </>
                                                    : <>
                                                        <Select onChange={this.onChangeDatas} name={"year"}
                                                                value={this.state.datas.year}>
                                                            {
                                                                FORMATIONS[this.state.datas.cycle].value[this.state.datas.study].value.map((f, i) => {
                                                                    return <MenuItem value={i}>{f}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </>
                                            }
                                        </FormControl>
                                    </div>
                                </>
                                : <></>
                        }
                        {
                            this.state.datas.cycle === 1 && this.state.datas.year !== null && this.state.datas.study !== null
                                ? <div className="col-6 mt-2">
                                    <p className={"mb-1 fw-bold"}>{this.state.datas.cycle === 1 && this.state.datas.study === 0 ? "Spécialités" : "Formation"}</p>
                                    <Autocomplete
                                        openOnFocus
                                        multiple={this.state.datas.cycle === 1 && this.state.datas.study === 0}
                                        options={FORMATIONS[1].value[this.state.datas.study].speciality.sort((a, b) => a.localeCompare(b))}
                                        value={this.state.datas.speciality !== null ? this.state.datas.speciality : []}
                                        onChange={(e, v) => this.onChangeAutocomplete("speciality", v)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </div>
                                : <></>
                        }
                        {
                            this.state.datas.year !== null && (this.state.datas.cycle === 0 || this.state.datas.speciality !== null)
                                ? <div className="col-6 mt-2">
                                    <p className={"mb-1 fw-bold"}>Matière</p>
                                    <Autocomplete
                                        freeSolo
                                        clearOnBlur
                                        openOnFocus
                                        options={SUBJECTS.sort((a, b) => a.localeCompare(b))}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            if (params.inputValue !== '') {
                                                filtered.push(params.inputValue);
                                            }

                                            return filtered;
                                        }}
                                        value={this.state.datas.subject}
                                        onChange={(e, v) => this.onChangeAutocomplete("subject", v)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </div>
                                : <></>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color={"primary"} size={"small"} onClick={() => {
                        this.setState({newModal: false})
                    }}>Continuer</Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={this.state.visualize}
                onHide={() => {
                    this.setState({visualize: false})
                }}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Body>
                    <div className="sheet-container">
                        <div className="header row align-items-center">
                            <div className="col-auto text-center">
                                <div>
                                    {this.state.datas.cycle !== null ? FORMATIONS[this.state.datas.cycle]['name'] : ""}
                                    <br/>
                                    {/*{this.state.datas.cycle === 0 || this.state.datas.cycle === 2 ? FORMATIONS[this.state.datas.cycle]['value'][this.state.datas.year] : FORMATIONS[this.state.datas.cycle]['value'][this.state.study]['value'][this.state.datas.year]}*/}
                                    {(this.state.datas.cycle !== null && (this.state.datas.cycle !== 1 || (this.state.datas.study !== null))) ? (this.state.datas.cycle === 0 || this.state.datas.cycle === 2 ? FORMATIONS[this.state.datas.cycle]['value'][this.state.datas.year] : FORMATIONS[this.state.datas.cycle]['value'][this.state.datas.study]['value'][this.state.datas.year]) : ""}
                                </div>
                            </div>
                            <div className="col text-center">
                                <h1 className="title">{this.state.datas.title}</h1>
                            </div>
                            <div className="col-auto">
                                {this.state.datas.subject}
                            </div>
                        </div>

                        {
                            this.state.premium && this.state.premiumEditor
                                ? <>
                                    {
                                        this.state.premiumEditor
                                            ? <>
                                                {
                                                    this.state.editor.map((e, i) => {
                                                        if (current <= i) {
                                                            if (e.type === 0 && e.value.title) {
                                                                current += 1
                                                                return <div className="definition"
                                                                            id="fiche_definition">
                                                                    <h4>Définition</h4>

                                                                    <div className="boxy">
                                                                        <div className="keyword">{e.value.title}</div>
                                                                        <div className="explain">{e.value.content}</div>
                                                                    </div>

                                                                    {
                                                                        this.state.editor.slice(i + 1, (this.state.editor.findIndex((e, index) => e.type !== 0 && index > i)) < 0 ? this.state.editor.length : this.state.editor.findIndex((e, index) => e.type !== 0 && index > i)).map((d) => {
                                                                            current += 1
                                                                            return <div className="boxy">
                                                                                <div
                                                                                    className="keyword">{d.value.title}</div>
                                                                                <div
                                                                                    className="explain">{d.value.content}</div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>

                                                            }
                                                            if (e.type === 2 || e.type === 1) {
                                                                current += 1
                                                                return <div className="body" id="fiche_body">
                                                                    {
                                                                        <>
                                                                            {
                                                                                e.type === 2
                                                                                    ? <div className="zone-img">
                                                                                        <img
                                                                                            src={checkIfB64(e['value']['content']) ? e['value']['content'] : `${process.env.REACT_APP_S3}sheetsImages/${e['value']['content']}`}/>
                                                                                        <p>{e['value']['title']}</p>
                                                                                    </div>
                                                                                    : parse(e['value'])
                                                                            }
                                                                            {
                                                                                this.state.editor.slice(i + 1, (this.state.editor.findIndex((e, index) => e.type !== 1 && e.type !== 2 && index > i)) < 0 ? this.state.editor.length : this.state.editor.findIndex((e, index) => e.type !== 1 && e.type !== 2 && index > i)).map((d) => {
                                                                                    current += 1
                                                                                    return <>
                                                                                        {
                                                                                            d.type === 1
                                                                                                ? parse(d.value)
                                                                                                : <div
                                                                                                    className="zone-img">
                                                                                                    <img
                                                                                                        src={checkIfB64(d['value']['content']) ? d['value']['content'] : `${process.env.REACT_APP_S3}sheetsImages/${d['value']['content']}`}/>
                                                                                                    <p>{d['value']['title']}</p>
                                                                                                </div>
                                                                                        }
                                                                                    </>
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            }
                                                            if (e.type === 3) {
                                                                current += 1
                                                                return <div>
                                                                    {parse(e.value)}
                                                                </div>
                                                            }
                                                            if (e.type === 4 && e['value'] !== "") {
                                                                current += 1
                                                                return <div className="resum" id="fiche_resum">
                                                                    <h4>A retenir :</h4>

                                                                    <div className="boxy">
                                                                        <div className="keyword">
                                                                            {parse(e['value'])}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        }
                                                    })
                                                }
                                                {
                                                    this.state.datas.withDefintions && this.state.editor.filter(e => e.type === 0 && e.value.title).length > 0
                                                        ? <>
                                                            <div className="definition-recap definition">
                                                                <h2>Définitions</h2>
                                                                {
                                                                    this.state.editor.filter(e => e.type === 0).map((d) => {
                                                                        return <div className="boxy">
                                                                            <div className="keyword">{d.value.title}</div>
                                                                            <div className="explain">{d.value.content}</div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                        : <></>
                                                }
                                            </>
                                            : <>
                                                {
                                                    this.state.editor.filter((e) => e.type === 0).filter((e) => e.value.title && e.value.content).length > 0
                                                        ? <>
                                                            <div className="definition" id="fiche_definition">
                                                                <h4>Définition</h4>

                                                                <div className="boxy">
                                                                    {
                                                                        this.state.editor.filter((e) => e.type === 0).map(d => {
                                                                            return <>
                                                                                <div
                                                                                    className="keyword">{d.value.title}</div>
                                                                                <div
                                                                                    className="explain">{d.value.content}</div>
                                                                            </>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        : <></>
                                                }
                                                {
                                                    this.state.editor.filter((e) => e.type === 2).filter((e) => e.value.content).length > 0 || this.state.editor.filter((e) => e.type === 1).filter((e) => e.value).length > 0
                                                        ? <>
                                                            <div className="body" id="fiche_body">
                                                                {
                                                                    this.state.editor.filter((e) => e.type === 2).map((i) => {
                                                                        return <>
                                                                            {
                                                                                i['value']['content'] !== ""
                                                                                    ? <div className="zone-img">
                                                                                        <img
                                                                                            src={checkIfB64(i['value']['content']) ? i['value']['content'] : `${process.env.REACT_APP_S3}sheetsImages/${i['value']['content']}`}
                                                                                        />
                                                                                        <p>{i['value']['title']}</p>
                                                                                    </div>
                                                                                    : <></>
                                                                            }
                                                                        </>
                                                                    })
                                                                }
                                                                {
                                                                    this.state.editor.filter((e) => e.type === 1).map((i) => {
                                                                        return <>
                                                                            {
                                                                                i['value'] !== ""
                                                                                    ? parse(i['value'])
                                                                                    : <></>
                                                                            }
                                                                        </>
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                        : <></>
                                                }
                                            </>
                                    }

                                </>
                                : <>
                                    {
                                        this.state.editor.filter((e) => e.type === 0).filter((e) => e.value.title && e.value.content).length > 0
                                            ? <>
                                                <div className="definition" id="fiche_definition">
                                                    <h4>Définition</h4>

                                                    <div className="boxy">
                                                        {
                                                            this.state.editor.filter((e) => e.type === 0).map(d => {
                                                                return <>
                                                                    <div className="keyword">{d.value.title}</div>
                                                                    <div className="explain">{d.value.content}</div>
                                                                </>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                            : <></>
                                    }
                                    {
                                        this.state.editor.filter((e) => e.type === 2).filter((e) => e.value.content).length > 0 || this.state.editor.filter((e) => e.type === 1).filter((e) => e.value).length > 0
                                            ? <>
                                                <div className="body" id="fiche_body">

                                                    {
                                                        this.state.premium
                                                            ? <>
                                                                {
                                                                    this.state.editor.filter((e) => e.type === 2).map(e => {
                                                                        return <>
                                                                            {
                                                                                e['value']['content'] !== ""
                                                                                    ? <div className="zone-img">
                                                                                        <img
                                                                                            src={checkIfB64(e['value']['content']) ? e['value']['content'] : `${process.env.REACT_APP_S3}sheetsImages/${e['value']['content']}`}
                                                                                        />
                                                                                        <p>{e['value']['title']}</p>
                                                                                    </div>
                                                                                    : <></>
                                                                            }
                                                                        </>
                                                                    })
                                                                }

                                                                {
                                                                    this.state.editor.filter((e) => e.type === 1).map(e => {
                                                                        return <>
                                                                            {
                                                                                e['value'] !== ""
                                                                                    ? parse(e['value'])
                                                                                    : <></>
                                                                            }
                                                                        </>
                                                                    })
                                                                }
                                                            </>
                                                            : <>
                                                                {
                                                                    this.state.editor.filter((e) => e.type === 2)[0] && this.state.editor.filter((e) => e.type === 2)[0]['value']['content'] !== ""
                                                                        ? <div className="zone-img">
                                                                            <img
                                                                                src={checkIfB64(this.state.editor.filter((e) => e.type === 2)[0]['value']['content']) ? this.state.editor.filter((e) => e.type === 2)[0]['value']['content'] : `${process.env.REACT_APP_S3}sheetsImages/${this.state.editor.filter((e) => e.type === 2)[0]['value']['content']}`}
                                                                            />
                                                                            <p>{this.state.editor.filter((e) => e.type === 2)[0]['value']['title']}</p>
                                                                        </div>
                                                                        : <></>
                                                                }


                                                                {
                                                                    this.state.editor.filter((e) => e.type === 1)[0] && this.state.editor.filter((e) => e.type === 1)[0]['value'] !== ""
                                                                        ? parse(this.state.editor.filter((e) => e.type === 1)[0]['value'])
                                                                        : <></>
                                                                }
                                                            </>
                                                    }


                                                    {/*{*/}
                                                    {/*    this.state.editor.filter((e) => e.type === 2)[0] && this.state.editor.filter((e) => e.type === 2)[0]['value']['content'] !== ""*/}
                                                    {/*        ? <div className="zone-img">*/}
                                                    {/*            <img*/}
                                                    {/*                src={checkIfB64(this.state.editor.filter((e) => e.type === 2)[0]['value']['content']) ? this.state.editor.filter((e) => e.type === 2)[0]['value']['content'] : process.env.REACT_APP_SERVER + "sheet/image?file=" + this.state.editor.filter((e) => e.type === 2)[0]['value']['content']}*/}
                                                    {/*            />*/}
                                                    {/*            <p>{this.state.editor.filter((e) => e.type === 2)[0]['value']['title']}</p>*/}
                                                    {/*        </div>*/}
                                                    {/*        : <></>*/}
                                                    {/*}*/}


                                                    {/*{*/}
                                                    {/*    this.state.editor.filter((e) => e.type === 1)[0] && this.state.editor.filter((e) => e.type === 1)[0]['value'] !== ""*/}
                                                    {/*        ? parse(this.state.editor.filter((e) => e.type === 1)[0]['value'])*/}
                                                    {/*        : <></>*/}
                                                    {/*}*/}
                                                </div>
                                            </>
                                            : <></>
                                    }
                                    {
                                        this.state.editor.filter((e) => e.type === 4).length > 0
                                            ? <>

                                                {

                                                    this.state.premium
                                                        ? <>
                                                            {
                                                                this.state.editor.filter((e) => e.type === 4).map((e) => {
                                                                    if (e['value']){
                                                                        return <>
                                                                            <div className="resum" id="fiche_resum">
                                                                                <h4>A retenir :</h4>

                                                                                <div className="boxy">
                                                                                    <div className="keyword">
                                                                                        {parse(e['value'])}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                })
                                                            }
                                                        </>
                                                        : <>
                                                            {
                                                                this.state.editor.filter((e) => e.type === 4)[0]['value']
                                                                    ?
                                                                    <>
                                                                        <div className="resum" id="fiche_resum">
                                                                            <h4>A retenir :</h4>

                                                                            <div className="boxy">
                                                                                <div className="keyword">
                                                                                    {parse(this.state.editor.filter((e) => e.type === 4)[0]['value'])}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : <></>
                                                            }

                                                        </>
                                                }
                                            </>
                                            : <></>
                                    }
                                </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outlined" color={"primary"} size={"small"} onClick={() => {
                        this.setState({visualize: false})
                    }}>Retour</Button>
                    <Button variant="contained" color={"primary"} size={"small"} onClick={async () => {
                        await this.resume()
                    }}>Enregistrer</Button>
                </Modal.Footer>
            </Modal>

            <Snackbar open={this.state.success}
                      anchorOrigin={{vertical: "top", horizontal: "center"}}
                      autoHideDuration={6000}
                      onClose={() => {
                          this.setState({success: false})
                      }}>
                <Alert onClose={() => {
                    this.setState({success: false})
                }} severity="success" variant={"filled"}>
                    Les modifications ont bien été enregistrées.
                </Alert>
            </Snackbar>
            <Snackbar open={this.state.error}
                      anchorOrigin={{vertical: "top", horizontal: "center"}}
                      autoHideDuration={6000}
                      onClose={() => {
                          this.setState({error: false})
                      }}>
                <Alert onClose={() => {
                    this.setState({error: false})
                }} severity="error" variant={"filled"}>
                    Une erreur s'est produite.
                </Alert>
            </Snackbar>
        </>
    }

}

export default connect(mapStateToProps)(Generator)
