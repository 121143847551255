import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'

import ReduxThunk from 'redux-thunk'
import authReducer from './reducers/authReducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
}

const allReducers = combineReducers({
  authReducer,
})

const persistedReducer = persistReducer(persistConfig, allReducers) // create a persisted reducer

const store = createStore(
  persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
  composeWithDevTools(applyMiddleware(ReduxThunk)), // add any middlewares here
)

const persistor = persistStore(store) // used to create the persisted store, persistor will be used in the next step

export { store, persistor }

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
