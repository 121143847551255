import { Button, Typography } from '@mui/material'
import {
  Mutation,
  SheetManagementType,
} from 'helpers/utils/__generated__/resolvers-types'
import React, { useEffect } from 'react'

import { Delete } from '@mui/icons-material'
import Modal from 'Component/Modal'
import { SHEET_MANAGEMENT } from 'helpers/graphql/mutation/sheet.graphql'
import { useMutation } from '@apollo/client'

type DeleteModalProps = {
  current: number | null
  onClose: () => void
  refetch: () => void
}
const DeleteModal = (props: DeleteModalProps) => {
  const { onClose, current, refetch } = props

  const [updateSheet, { data }] = useMutation<Mutation>(SHEET_MANAGEMENT)

  useEffect(() => {
    if (data?.SheetManagement) {
      refetch()
      onClose()
    }
  }, [data])

  return (
    <Modal
      open={!!current}
      onClose={onClose}
      header={'Supprimer cette fiche ?'}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          color="error"
          startIcon={<Delete />}
          onClick={() =>
            updateSheet({
              variables: {
                sheet: current,
                type: SheetManagementType.Delete,
              },
            })
          }
        >
          Supprimer
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteModal
