import 'leaflet/dist/leaflet.css'
import './analytics.css'

import { Bar, Line } from 'react-chartjs-2'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js'
import {
  CircleMarker,
  GeoJSON,
  Tooltip as LeafTooltip,
  MapContainer,
  Marker,
  TileLayer,
} from 'react-leaflet'
import React, { Component, useEffect, useState } from 'react'
import { formatHour, secToMin } from '../Service/Functions'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Icon } from 'leaflet'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import MarkerClusterGroup from 'react-leaflet-markercluster';
import MarkerClusterGroup from 'react-leaflet-cluster'
import TextField from '@mui/material/TextField'
import { Tooltip } from '@material-ui/core'
import axios from 'axios'
import frLocale from 'date-fns/locale/fr'
import mapData from '../assets/countries.json'
import { store } from '../store/store'

// import mapData from '../assets/custom.geo.json'
const getCountryISO3 = require('country-iso-2-to-3')

const url = 'https://matomo.aloisguitton.fr/'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
)

require('leaflet/dist/leaflet.css')

const get = (params = {}) => {
  console.log('aze')

  params = {
    module: 'API',
    idSite: '1',
    period: 'day',
    format: 'JSON',
    token_auth: 'c56391c1e2faa3bd279d544127e5ad6f',
    language: 'en',
    ...params,
  }

  return new Promise((resolve, reject) => {
    const config = {
      method: 'get',
      url: url,
      params: params,
    }

    axios(config)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export default function Analytics(props) {
  const [visitor, setVisitor] = useState(null)
  const [countries, setCountries] = useState(null)
  const [last30, setLast30] = useState(null)
  const [last24h, setLast24h] = useState(null)
  const [lastVisit, setLastVisit] = useState([])
  const [visitByDays, setVisitByDays] = useState(null)
  const [visitByHours, setVisitByHours] = useState(null)
  const [referrers, setReferrers] = useState(null)
  const [userLast30, setUserLast30] = useState(null)
  const [summary, setSumarry] = useState(null)
  const [startDate, setStartDate] = useState(
    new Date(new Date() - 30 * 24 * 60 * 60 * 1000),
  )
  const [endDate, setEndDate] = useState(new Date())

  const formatRequestDate = () => {
    return `${startDate.toISOString().substring(0, 10)},${endDate
      .toISOString()
      .substring(0, 10)}`
  }

  const getDataInterval = () => {
    get({
      method: 'Live.getCounters',
      lastMinutes: 30,
    }).then(res => {
      setLast30(res.data[0])

      get({
        method: 'Live.getLastVisitsDetails',
        countVisitorsToFetch: res.data[0].visits,
      }).then(res => {
        setLastVisit(res.data)
        const date = {
          labels: [],
          datasets: [
            {
              label: 'Visiteurs',
              data: [],
              backgroundColor: 'rgb(25, 103, 210)',
            },
          ],
        }
        const currentDate = new Date().setSeconds(0, 0)
        const dateLabel = []
        const dateData = []

        for (let i = 1; i <= 30; i++) {
          dateLabel.push(currentDate - (30 - i) * 60 * 1000)
          dateData.push(0)
        }

        res.data.map(a => {
          const date = new Date(a.lastActionTimestamp * 1000).setSeconds(0)

          dateData[dateLabel.indexOf(date)] += 1
        })

        dateLabel.forEach((d, i) => {
          dateLabel[i] = formatHour(d)
        })
        date.datasets[0].data = dateData
        date.labels = dateLabel
        // console.log(date);
        // setUserLast30(JSON.parse('{"labels":["January","February","March","April","May","June","July"],"datasets":[{"label":"Dataset 1","data":[451,386,937,995,939,680,794],"backgroundColor":"rgba(255, 99, 132, 0.5)"},{"label":"Dataset 2","data":[420,244,221,399,324,314,179],"backgroundColor":"rgba(53, 162, 235, 0.5)"}]} '))
        setUserLast30(date)
      })
    })

    get({
      method: 'Live.getCounters',
      lastMinutes: 60 * 24,
    }).then(res => {
      setLast24h(res.data[0])
    })
  }

  const getData = () => {
    get({
      method: 'VisitsSummary.get',
      date: formatRequestDate(),
      period: 'range',
    }).then(res => {
      setSumarry(res.data)
      console.log(res.data)
    })

    get({
      method: 'VisitsSummary.get',
      date: formatRequestDate(),
    }).then(res => {
      const { data } = res
      const datasets = [
        {
          label: 'Visites',
          data: [],
          radius: 1.3,
          tension: 0.1,
          borderWidth: 2,
          // backgroundColor: 'rgba(25, 103, 210,0.6)',
          // fill: true,
          borderColor: 'rgb(25, 103, 210)',
        },
      ]

      const labels = Object.keys(data)
      labels.map(label => {
        datasets[0].data = [...datasets[0].data, data[label].nb_uniq_visitors]
      })
      setVisitor({
        datasets,
        labels,
      })
    })
    get({
      method: 'UserCountry.getCountry',
      date: formatRequestDate(),
      period: 'range',
    }).then(res => {
      if (res.data.length > 0) {
        res.data.sort((a, b) => b.nb_visits - a.nb_visits)
        const max = res.data[0].nb_visits
        const countriesList = { ...mapData }
        res.data.map(country => {
          const c = mapData.features.filter(
            c =>
              c.properties.ISO_A3.toUpperCase() ===
              getCountryISO3(country.code.toUpperCase()),
          )
          const index = mapData.features.indexOf(c[0])
          if (index > -1) {
            let color = country.nb_visits / max
            if (color < 0.1) color = 0.1
            countriesList.features[index].properties.color = color
            countriesList.features[index].properties.nb_visits =
              country.nb_visits
          }
        })
        setCountries({ ...countriesList })
      }
    })

    get({
      method: 'Referrers.get',
      date: formatRequestDate(),
      period: 'range',
    }).then(res => {
      setReferrers(res.data)
    })

    get({
      method: 'VisitTime.getByDayOfWeek',
      date: formatRequestDate(),
      period: 'range',
    }).then(res => {
      let total = 0
      res.data.map(d => {
        total += d.nb_visits
      })
      setVisitByDays({
        labels: res.data.map(d => d.label),
        datasets: [
          {
            label: '% des visites',
            data: res.data.map(d => (d.nb_visits / total) * 100),
            backgroundColor: 'rgb(25, 103, 210)',
          },
        ],
      })
    })

    get({
      method: 'VisitTime.getVisitInformationPerLocalTime',
      date: formatRequestDate(),
      period: 'range',
    }).then(res => {
      let total = 0
      res.data.map(d => {
        total += d.nb_visits
      })
      setVisitByHours({
        labels: res.data.map(d => d.label),
        datasets: [
          {
            label: '% des visites',
            data: res.data.map(d => (d.nb_visits / total) * 100),
            backgroundColor: 'rgb(25, 103, 210)',
          },
        ],
      })
    })
  }

  useEffect(() => {
    setInterval(() => {
      getDataInterval()
    }, 60 * 1000)
    getDataInterval()
    getData()
  }, [])

  useEffect(() => {
    getData()
  }, [startDate, endDate])

  const countryStyle = {
    fillColor: 'rgb(25, 103, 210)',
    color: 'black',
    weight: 0,
  }

  const onEachCountry = (country, layer) => {
    const countryName = country.properties.ADMIN
    if (country.properties.color) {
      layer.bindPopup(
        `${countryName} <br/> ${country.properties.nb_visits} visites`,
      )
      layer.options.fillOpacity = country.properties.color
    } else {
      layer.options.fillOpacity = 0
    }
  }

  return (
    <div id="analytics">
      <div id="menu">
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <div className="row">
            <div className="col-6 px-2 datepicker">
              <DatePicker
                mask="__/__/____"
                label="Début"
                value={startDate}
                onChange={newValue => {
                  setStartDate(newValue)
                }}
                renderInput={params => <TextField {...params} />}
              />
            </div>
            <div className="col-6 px-2 datepicker">
              <DatePicker
                mask="__/__/____"
                label="Fin"
                value={endDate}
                onChange={newValue => {
                  setEndDate(newValue)
                }}
                renderInput={params => <TextField {...params} />}
              />
            </div>
          </div>
        </LocalizationProvider>
      </div>
      <div id="content">
        <div>
          <div id="realtime-table" className="table">
            <div className="header">
              <div>Dates</div>
              <div>Visites</div>
              <div>Actions</div>
            </div>
            {last24h ? (
              <div>
                <div>Dernières 24 heures</div>
                <div>{last24h.visits}</div>
                <div>{last24h.actions}</div>
              </div>
            ) : null}
            {last30 ? (
              <div>
                <div>Dernières 30 minutes</div>
                <div>{last30.visits}</div>
                <div>{last30.actions}</div>
              </div>
            ) : null}
          </div>
          {userLast30 ? <Bar data={userLast30} /> : null}

          <div id="last-visit">
            {lastVisit.map(v => {
              return (
                <div>
                  <div>
                    {v.lastActionDateTime} ({v.visitDurationPretty})
                    {v.visitorType === 'returning' ? (
                      <Tooltip
                        title={<>Visiteur de retour: {v.visitCount} visites</>}
                        arrow
                      >
                        <img src={url + v.visitorTypeIcon} alt="" />
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                    <Tooltip
                      title={
                        <>
                          Pays: {v.country}
                          <br />
                          Région: {v.region}
                          <br />
                          Ville: {v.city}
                          <br />
                          IP: {v.visitIp}
                          <br />
                        </>
                      }
                      arrow
                    >
                      <img src={url + v.countryFlag} />
                    </Tooltip>
                    <Tooltip title={<>Navigateur: {v.browser}</>} arrow>
                      <img src={url + v.browserIcon} />
                    </Tooltip>
                    <Tooltip
                      title={<>Système d'exploitation: {v.operatingSystem}</>}
                      arrow
                    >
                      <img src={url + v.operatingSystemIcon} />
                    </Tooltip>
                    <Tooltip
                      title={
                        <>
                          Type du périphérique : {v.deviceType} <br />
                          Marque: {v.deviceBrand} <br />
                          Résolution : {v.resolution}
                        </>
                      }
                      arrow
                    >
                      <img src={url + v.deviceTypeIcon} />
                    </Tooltip>
                    {v.referrerType === 'direct' ? (
                      <span>Entrée directe</span>
                    ) : (
                      <>
                        <img src={url + v.referrerSearchEngineIcon} />
                        {v.referrerName}
                      </>
                    )}
                  </div>
                  <div>
                    <span>Actions</span>
                    {v.actionDetails
                      .filter(a => a.type === 'action')
                      .slice(0, 30)
                      .map(a => {
                        return (
                          <Tooltip
                            title={
                              <>
                                {a.url}
                                <br />
                                {a.pageTitle}
                                <br />
                                Temps sur la page : {a.timeSpentPretty}
                              </>
                            }
                            arrow
                          >
                            <img src={url + a.iconSVG} />
                          </Tooltip>
                        )
                      })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <div id="summary">
            {summary ? (
              <>
                <div>
                  <span>Nombre de visite</span>
                  <br />
                  {summary.nb_visits}
                </div>
                <div>
                  <span>Temps moyen sur le site</span>
                  <br />
                  {secToMin(summary.avg_time_on_site)}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {visitor ? (
            // <Line data={JSON.parse('{"labels":["January","February","March","April","May","June","July"],"datasets":[{"label":"Dataset 1","data":[441,652,23,-14,174,-145,-427],"borderColor":"rgb(255, 99, 132)","backgroundColor":"rgba(255, 99, 132, 0.5)"},{"label":"Dataset 2","data":[-475,-154,706,-52,-50,-427,690],"borderColor":"rgb(53, 162, 235)","backgroundColor":"rgba(53, 162, 235, 0.5)"}]}')} />
            <Line data={visitor} />
          ) : null}
          {visitByDays ? <Bar data={visitByDays} /> : null}
          {visitByHours ? <Bar data={visitByHours} /> : null}

          <div id="referrers-table" className="table">
            <div className="header">
              <div>Type de canal</div>
              <div>Visites</div>
            </div>

            {referrers ? (
              <>
                <div>
                  <div>Entrées directes</div>
                  <div>{referrers.Referrers_visitorsFromDirectEntry}</div>
                </div>
                <div>
                  <div>Moteurs de recherche</div>
                  <div>{referrers.Referrers_visitorsFromSearchEngines}</div>
                </div>
                <div>
                  <div>Réseaux sociaux</div>
                  <div>{referrers.Referrers_visitorsFromSocialNetworks}</div>
                </div>
                <div>
                  <div>Sites web</div>
                  <div>{referrers.Referrers_visitorsFromWebsites}</div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <MapContainer
            zoom={2}
            center={[0, 15]}
            scrollWheelZoom={false}
            style={{ height: '50vh' }}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <MarkerClusterGroup>
              {lastVisit
                .filter(v => v.latitude !== null && v.longitude !== null)
                .map((v, i) => {
                  return (
                    <CircleMarker
                      center={[v.latitude, v.longitude]}
                      radius={20}
                    >
                      <LeafTooltip direction="top" opacity={1}>
                        <div className="leaflet-tooltip-div">
                          {v.lastActionDateTime} ({v.visitDurationPretty})
                          <br />
                          {v.visitorType === 'returning' ? (
                            <span>
                              <img src={url + v.visitorTypeIcon} alt="" />
                              Visiteur de retour: {v.visitCount} visites
                            </span>
                          ) : (
                            <></>
                          )}
                          <br />
                          <img src={url + v.countryFlag} />
                          <img src={url + v.browserIcon} />
                          <img src={url + v.operatingSystemIcon} />
                          <img src={url + v.deviceTypeIcon} />
                          <br />
                          Région: {v.region}
                          <br />
                          Ville: {v.city}
                          <br />
                          IP: {v.visitIp}
                          <br />
                        </div>
                      </LeafTooltip>
                    </CircleMarker>
                  )
                })}
            </MarkerClusterGroup>
          </MapContainer>
          <MapContainer
            style={{ height: '50vh' }}
            zoom={2}
            center={[0, 15]}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {countries !== null ? (
              <GeoJSON
                style={countryStyle}
                data={countries.features}
                onEachFeature={onEachCountry}
              />
            ) : (
              <></>
            )}
          </MapContainer>
        </div>
      </div>
    </div>
  )
}
