import { Alert, Button, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { LOGIN } from 'helpers/graphql/mutation/login.graphql'
import { Mutation } from 'helpers/utils/__generated__/resolvers-types'
import { connect } from 'react-redux'
import { useMutation } from '@apollo/client'

let md5 = require('md5')

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action)
    },
  }
}

const Login = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [login, { data, error }] = useMutation<Mutation>(LOGIN)

  useEffect(() => {
    if (data?.LoginAdmin) {
      const action = {
        type: 'CONNECT',
        value: {
          token: data.LoginAdmin.token,
          level: data.LoginAdmin.level,
        },
      }
      props.dispatch(action)
    }
  }, [data])

  return (
    <>
      <Typography variant={'h1'} align={'center'} className={'py-4'}>
        Connexion administrateur
      </Typography>
      <div className="container">
        <div className="row pt-5">
          {error && (
            <Alert className={'my-2'} severity="warning">
              Identifiants incorrects!
            </Alert>
          )}
          <TextField
            name={'email'}
            type={'email'}
            label={'Adresse email'}
            className={'w-100 col-12 mb-2'}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            name={'password'}
            type={'password'}
            label={'Mot de passe'}
            className={'w-100 col-12 mb-2'}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            className={'mx-auto col-12'}
            onClick={() =>
              login({
                variables: {
                  username: email,
                  password: md5(password),
                },
              })
            }
          >
            Se connecter
          </Button>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
