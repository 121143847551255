import React, { Component } from "react";
import { SortTable } from "../../Component/Table/table";
import { del, get, put } from "../../Service/Requests";
import { Modal } from "react-bootstrap";
import {
    Alert, Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Snackbar,
    TextareaAutosize,
    TextField, Button, Typography
} from "@mui/material"
import { useState } from "react";
import { useEffect } from "react";

export function Definitions(props) {
    const [definitions, setDefinitions] = useState([])
    const [modal, setModal] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [currentDefinition, setCurrentDefinition] = useState(null)
    const [name, setName] = useState(null)
    const [value, setValue] = useState(null)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        get('admin/definitions')
            .then((res) => {
                setDefinitions(res.data.definitions)
            })
    }, [])

    const actions = (cell, row) => {
        let def = definitions.find(d => d.id === row.id)

        return <>
            <div className="text-center">
                <button
                    className="mx-1 btn btn-primary"
                    onClick={() => {
                        let current = definitions.filter(d => d.id === def.id)[0]
                        setCurrentDefinition(def.id)
                        setModal(true)
                        setName(current.name)
                        setValue(current.value)
                    }}
                >
                    Modifier
                </button>
                <button
                    className="mx-1 btn btn-danger mt-1"
                    onClick={() => {
                        setCurrentDefinition(def.id)
                        setModalDelete(true)
                    }}
                >
                    Supprimer
                </button>
            </div>
        </>
    }

    const columns = [
        {
            dataField: 'id',
            text: 'id',
            sort: true
        },
        {
            dataField: 'name',
            text: 'Titre',
            sort: true
        },
        {
            dataField: 'value',
            text: 'Définition',
            sort: true
        },
        {
            dataField: "actions",
            text: "Actions",
            formatter: actions
        }
    ];

    return <>
        <Typography variant={"h1"} align={"center"}>Liste des définitions</Typography>
        <Typography variant={"body1"} className={"info2 mb-3"}>Nombres de définitions
            : {definitions.length}</Typography>
        <SortTable data={definitions} columns={columns} />

        <Modal show={modal} centered onHide={() => setModal(false)}
            size={"lg"}>
            <Modal.Header closeButton className={"position-relative"}>
            </Modal.Header>
            <Modal.Body>
                <div className="w-100">
                    <TextField
                        variant={"outlined"}
                        className={'w-100'}
                        placeholder={"Titre"}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                    />
                    <TextareaAutosize
                        placeholder="Définition"
                        rows={5}
                        className={"w-100 my-3"}
                        value={value}
                        name={"content"}
                        onChange={(e) => {
                            setValue(e.target.value)
                        }}
                    />
                    <div className="w-100 text-center">
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            onClick={() => {
                                put("admin/definition", {
                                    id: currentDefinition,
                                    name: name,
                                    value: value
                                }).then(r => {
                                    setSuccess(true)
                                    setModal(false)
                                })
                            }}
                        >
                            Modifier
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={modalDelete} centered onHide={() => setModalDelete(false)}>
            <Modal.Header closeButton className={"position-relative"}>
            </Modal.Header>
            <Modal.Body>
                {
                    currentDefinition
                        ? <div className="row">
                            <Typography variant={"h3"} component={"p"} align={"text"}>Supprimer la définition {definitions.filter(d => d.id === currentDefinition)[0]['name']} ?</Typography>
                            <div className="col-12 text-center">
                                <button className={"btn btn-danger"} onClick={() => {
                                    del("admin/definition", { id: currentDefinition })
                                        .then(() => {
                                            setSuccess(true)
                                            setModalDelete(false)
                                        })
                                }}>Supprimer</button>
                            </div>
                        </div>
                        : <></>
                }
            </Modal.Body>
        </Modal>
        <Snackbar open={success}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
                setSuccess(false)
            }}>
            <Alert onClose={() => {
                setSuccess(false)
            }} severity="success" variant={"filled"}>
                Les modifications ont bien été enregistrées.
            </Alert>
        </Snackbar>
    </>
}
// export class Definitions2 extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             definitions: [],
//             modal: false,
//             currentDefinition: null,
//             name: null,
//             value: null,
//             success: false
//         }
//     }

//     componentDidMount() {
//         get('admin/definitions')
//             .then((res) => {
//                 this.setState({
//                     definitions: res.data.definitions
//                 })
//             })
//     }

//     actions = (cell, row) => {
//         let def = definitions.find(d => d.id === row.id)

//         return <>
//             <div className="text-center">
//                 <button
//                     className="mx-1 btn btn-primary"
//                     onClick={() => {
//                         let current = definitions.filter(d => d.id === def.id)[0]
//                         this.setState({
//                             currentDefinition: def.id,
//                             modal: true,
//                             name: current.name,
//                             value: current.value
//                         })
//                     }}
//                 >
//                     Modifier
//                 </button>
//                 <button
//                     className="mx-1 btn btn-danger mt-1"
//                     onClick={() => {
//                         this.setState({
//                             currentDefinition: def.id,
//                             modalDelete: true,
//                         })
//                     }}
//                 >
//                     Supprimer
//                 </button>
//             </div>
//         </>
//     }

//     render() {
//         const columns = [
//             {
//                 dataField: 'id',
//                 text: 'id',
//                 sort: true
//             },
//             {
//                 dataField: 'name',
//                 text: 'Titre',
//                 sort: true
//             },
//             {
//                 dataField: 'value',
//                 text: 'Définition',
//                 sort: true
//             },
//             {
//                 dataField: "actions",
//                 text: "Actions",
//                 formatter: this.actions
//             }
//         ];

//         return <>
//             <Typography variant={"h1"} align={"center"}>Liste des définitions</Typography>
//             <Typography variant={"body1"} className={"info2 mb-3"}>Nombres de définitions
//                 : {definitions.length}</Typography>
//             <SortTable data={definitions} columns={columns} />

//             <Modal show={modal} centered onHide={() => this.setState({ modal: false })}
//                 size={"lg"}>
//                 <Modal.Header closeButton className={"position-relative"}>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="w-100">
//                         <TextField
//                             variant={"outlined"}
//                             className={'w-100'}
//                             placeholder={"Titre"}
//                             value={name}
//                             onChange={(e) => {
//                                 this.setState({ name: e.target.value })
//                             }}
//                         />
//                         <TextareaAutosize
//                             placeholder="Définition"
//                             rows={5}
//                             className={"w-100 my-3"}
//                             value={value}
//                             name={"content"}
//                             onChange={(e) => {
//                                 this.setState({ value: e.target.value })
//                             }}
//                         />
//                         <div className="w-100 text-center">
//                             <Button
//                                 variant={"contained"}
//                                 color={"secondary"}
//                                 onClick={() => {
//                                     put("admin/definition", {
//                                         id: currentDefinition,
//                                         name: name,
//                                         value: value
//                                     }).then(r => {
//                                         this.setState({
//                                             success: true,
//                                             modal: false
//                                         })
//                                     })
//                                 }}
//                             >
//                                 Modifier
//                             </Button>
//                         </div>
//                     </div>
//                 </Modal.Body>
//             </Modal>

//             <Modal show={modalDelete} centered onHide={() => this.setState({ modalDelete: false })}>
//                 <Modal.Header closeButton className={"position-relative"}>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {
//                         currentDefinition
//                             ? <div className="row">
//                                 <Typography variant={"h3"} component={"p"} align={"text"}>Supprimer la définition {definitions.filter(d => d.id === currentDefinition)[0]['name']} ?</Typography>
//                                 <div className="col-12 text-center">
//                                     <button className={"btn btn-danger"} onClick={() => {
//                                         del("admin/definition", { id: currentDefinition })
//                                             .then(() => {
//                                                 this.setState({
//                                                     success: true,
//                                                     modalDelete: false
//                                                 })
//                                             })
//                                     }}>Supprimer</button>
//                                 </div>
//                             </div>
//                             : <></>
//                     }
//                 </Modal.Body>
//             </Modal>
//             <Snackbar open={success}
//                 anchorOrigin={{ vertical: "top", horizontal: "center" }}
//                 autoHideDuration={6000}
//                 onClose={() => {
//                     this.setState({ success: false })
//                 }}>
//                 <Alert onClose={() => {
//                     this.setState({ success: false })
//                 }} severity="success" variant={"filled"}>
//                     Les modifications ont bien été enregistrées.
//                 </Alert>
//             </Snackbar>
//         </>
//     }
// }