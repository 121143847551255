import {
  AppBar,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core/'
import { Nav, NavDropdown, Navbar } from 'react-bootstrap'
import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import logo from '../assets/logo.png'

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action)
    },
  }
}

export class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAuth: this.isAuth,
    }
  }

  handleClick(e) {
    e.preventDefault()
    sessionStorage.clear()
    window.location.replace('/')
  }

  disconnect = () => {
    const action = { type: 'DISCONNECT', value: {} }
    this.props.dispatch(action)
    window.location.href = '/'
  }

  render() {
    const auth_level = sessionStorage.getItem('auth_level')

    return (
      <ElevationScroll {...this.props}>
        <AppBar id="navbar">
          <Navbar bg="light" expand="lg">
            <div className="container">
              <Navbar.Brand href="/">
                <img src={logo} alt="" style={{ height: '40px' }} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                className="justify-content-md-end"
                id="basic-navbar-nav"
              >
                <Nav>
                  {this.props.authReducer.loggedIn ? (
                    <ul className="navbar-nav mb-2 mb-lg-0 d-flex align-items-md-center">
                      {this.props.authReducer.level >= 3 ? (
                        <>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                typeof window !== 'undefined' &&
                                window.location.pathname === '/analytics'
                                  ? 'active'
                                  : ''
                              }`}
                              href="/analytics"
                            >
                              Analytics
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                typeof window !== 'undefined' &&
                                window.location.pathname === '/admin'
                                  ? 'active'
                                  : ''
                              }`}
                              href="/admin"
                            >
                              Administrateurs
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                typeof window !== 'undefined' &&
                                window.location.pathname === '/users'
                                  ? 'active'
                                  : ''
                              }`}
                              href="/users"
                            >
                              Utilisateurs
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                typeof window !== 'undefined' &&
                                window.location.pathname === '/sheets'
                                  ? 'active'
                                  : ''
                              }`}
                              href="/sheets"
                            >
                              Fiches
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                typeof window !== 'undefined' &&
                                window.location.pathname === '/email'
                                  ? 'active'
                                  : ''
                              }`}
                              href="/email"
                            >
                              Emails
                            </a>
                          </li>
                          {/* <li className="nav-item">
                                                                <a className={"nav-link " + (typeof window !== "undefined" && window.location.pathname === "/definitions" ? "active" : "")}
                                                                   href="/definitions">Définitions</a>
                                                            </li> */}
                        </>
                      ) : (
                        <></>
                      )}
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            typeof window !== 'undefined' &&
                            window.location.pathname === '/blog'
                              ? 'active'
                              : ''
                          }`}
                          href="/blog"
                        >
                          Blog
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href=""
                          onClick={e => {
                            e.preventDefault()
                            const action = {
                              type: 'DISCONNECT',
                            }
                            this.props.dispatch(action)
                          }}
                        >
                          Se déconnecter
                        </a>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </AppBar>
      </ElevationScroll>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)

function ElevationScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}
