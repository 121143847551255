import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import React from "react";


import './table.css'

const { SearchBar } = Search;

const CSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div style={{float: "right"}}>
            <button className="btn btn-success" onClick={ handleClick }>Exporter en CSV</button>
        </div>
    );
};

export function SortTable({data, columns}) {

    const options = {
        sizePerPageList: [{
            text: '25', value: 25
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: 'All', value: data.length
        }]
    };

    return <>
        <ToolkitProvider bootstrap4 keyField='partielo' data={data} columns={columns} search >
            {
                props => (
                    <div>
                        <SearchBar {...props.searchProps} />

                        <hr/>
                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory(options)}
                        />
                    </div>
                )
            }
        </ToolkitProvider>
    </>
}