export const FORMATIONS: any = [
  {
    name: "Collège",
    value: ["Sixième", "Cinquième", "Quatrième", "Troisième"],
  },
  {
    name: "Lycée",
    value: [
      {
        name: "Bac général et technologique",
        value: ["Seconde", "Première", "Terminale"],
        speciality: [
          "Histoire géographie, géopolitique et sciences politiques",
          "Humanités, littérature et philosophie",
          "Langues, littératures et cultures étrangères",
          "Mathématiques",
          "Physique-chimie",
          "Sciences de la vie et de la Terre",
          "Sciences économiques et sociales",
          "Arts : arts plastiques ou cinéma-audiovisuel ou danse ou histoire des arts ou musique ou théâtre",
          "Biologie écologie, dans les lycées agricoles uniquement",
          "Littérature, langues et cultures de l’Antiquité",
          "Numérique et sciences informatiques",
          "Sciences de l'ingénieur",
        ],
      },
      {
        name: "Bac pro",
        value: ["Seconde", "Première", "Terminale"],
        speciality: [
          "Logistique",
          "Hygiène et environnement",
          "Vente",
          "Accueil - relation clients et usagers",
          "Commerce",
          "Commerce international",
          "Gestion",
          "Administration des entreprises",
          "Secrétariat",
          "Aéronautique",
          "Aviation générale",
          "Agroéquipement",
          "Technicien aérostructures",
          "Cultures marines",
          "Conduite et gestion de l’exploitation agricole",
          "Productions aquacoles",
          "Ouvrage du bâtiment",
          "importruction des carrosseries",
          "Bio industrie transformation",
          "Métiers de l’alimentation",
          "Métiers de l'accueil",
          "Commerce",
          "Maintenance de véhicules automobiles",
          "Esthétique cosmétique parfumerie",
          "Travaux publics",
          "Aménagement et finition du bâtiment",
          "Photographie",
          "Électrotechnique, énergie, équipements communicants",
          "Intervention sur le patrimoine bâti",
          "Microtechnique",
          "Technicien d’études du bâtiment",
          "Technicien géomètre topographe",
          "Hygiène, Propreté et Stérilisation",
          "Micro-informatique et réseaux",
          "Vente, prospection, négociation, suivi de clientèle",
          "Production graphique",
          "Industrie de procédés",
          "Perruquier Posticheur",
          "Réalisation de produits imprimés et plurimédia",
          "Systèmes électroniques numériques",
          "Gestion des Pollutions et Protection de l’Environnement",
          "Environnement nucléaire",
          "Hygiène et Environnement",
          "Étude et Définition de Produits Industriels",
          "Industries des pates, papiers et cartons",
          "Industrie de transformation",
          "Fonderie",
          "Maintenance des équipements industriels",
          "Conducteur Transport Routier Marchandises (CTRM)",
          "Exploitation des transports",
          "Logistique",
          "Transport",
          "Technicien de maintenance",
          "Sécurité prévention",
          "Technicien menuisier-agenceur",
          "Systèmes Numériques (SN)",
          "Usinage",
          "Maintenance de véhicules",
          "Technicien en chaudronnerie industrielle",
          "Électrotechnique, énergie, équipement",
          "Microtechnique",
          "Maintenance des équipements industriels",
          "Maintenance qualité",
          "Étude et définition de produits industriels",
          "Productique mécanique",
          "Artisanat et métiers d’art",
          "Technicien menuisier agenceur",
          "Métiers de la mode",
          "Métiers du cuir option Maroquinerie",
          "Métiers du pressing et de la blanchisserie",
          "Sécurité Prévention",
          "Métiers de la sécurité",
          "ARCU (accueil relation clients et usagers)",
          "ASSP (Accompagnement, Soins et Services à la Personne)",
          "Gestion-Administration",
          "Services de Proximité et Vie Locale",
          "Cuisine",
          "Restauration",
          "Poissonnier écailler traiteur",
          "Boucher charcutier traiteur",
          "Boulanger - pâtissier",
          "Commercialisation et Services en Restauration",
        ],
      },
    ],
  },
  {
    name: "Post-Bac",
    value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
]

export const checkIfB64 = (file) => {
  console.log(file);
  
  let pattern = /^data:image\/.+;base64/;
  return pattern.test(file);
};


export const getBase64 = (file): Promise<string> => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result?.toString() || "";
      resolve(baseURL);
    };
  });
};