import * as React from 'react'

import {
  Button,
  TablePagination,
  TableSortLabel,
  TableSortLabelProps,
  TextField,
} from '@mui/material'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import { useEffect, useState } from 'react'

import { Download } from '@mui/icons-material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import styles from './style.module.scss'

export type TableCell = string | number | undefined | JSX.Element | CustomCell

export type TableRow = TableCell[]

type MuiTableOrderBy = {
  column: string
  direction: TableSortLabelProps['direction']
}
type MuiTableProps = {
  headers?: TableRow
  rows?: TableRow[]
  onSearch?: (e: string) => void
  orderBy?: MuiTableOrderBy
  setOrderBy?: (column: string) => void
  updatePage?: (page: number) => void
  total?: number
}

export type CustomCell = {
  children: React.ReactNode
  align?: TableCellProps['align']
  sort?: SortCustomCell
}

export type SortCell = {
  active: boolean
  direction: 'ASC' | 'DESC'
  onClick: (direction: 'ASC' | 'DESC') => void
}

export type SortCustomCell = {
  column: string
}
export const customCell = (
  children: JSX.Element | string | number,
  align?: TableCellProps['align'],
  sort?: SortCustomCell,
): CustomCell => ({
  children,
  align,
  sort,
})

type SortInfo = {
  onSort: () => void
  direction: TableSortLabelProps['direction']
  active: boolean
}

const createCell = (cell: TableCell, sortInfo?: SortInfo) => {
  if (cell === undefined) return <></>
  if (cell.hasOwnProperty('children')) {
    const customCell = cell as CustomCell

    if (customCell.sort) {
      return (
        <TableCell align={customCell.align || 'left'}>
          <TableSortLabel
            onClick={sortInfo?.onSort}
            direction={sortInfo?.direction}
            active={sortInfo?.active}
          >
            {customCell.children}
          </TableSortLabel>
        </TableCell>
      )
    }
    return (
      <TableCell align={customCell.align || 'left'}>
        {customCell.children}
      </TableCell>
    )
  } else {
    return <TableCell>{cell}</TableCell>
  }
}

const customCellToCSV = (cell: TableCell) => {
  if (cell === undefined) return ''

  if (cell.hasOwnProperty('children')) {
    const customCell = cell as CustomCell
    if (typeof customCell.children === 'object') return ''
    return `${customCell.children!.toString()} `
  } else {
    if (typeof cell === 'object') return ''
    return `${cell?.toString()} `
  }
}

const download = (header: TableRow, rows: TableRow[]) => {
  console.log(header)

  const csvHeader = header.reduce(
    (previous, cell) => `${previous}${customCellToCSV(cell)}; `,
    '',
  ) as string

  const csv = rows.reduce(
    (previous, row) =>
      `${previous}${
        row.reduce(
          (previousCells, cell) => `${previousCells}${customCellToCSV(cell)};`,
          '',
        ) as string
      } \n`,
    `${csvHeader} \n`,
  )

  const downloadUrl = window.URL.createObjectURL(
    new Blob([`\uFEFF${csv} `], {
      type: 'text/csv;charset=utf-8;',
    }),
  )
  const link = document.createElement('a')
  link.href = downloadUrl
  link.setAttribute('download', 'file.csv')
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const invertDir = (
  dir: TableSortLabelProps['direction'],
): TableSortLabelProps['direction'] => {
  if (dir === 'asc') return 'desc'
  return 'asc'
}

const MuiTable = (props: MuiTableProps) => {
  const { headers, rows, onSearch, orderBy, setOrderBy, total, updatePage } =
    props

  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (onSearch) {
      setPage(0)
      onSearch(search)
    }
  }, [search])

  useEffect(() => {
    console.log(page)

    if (updatePage) {
      updatePage(page + 1)
    }
  }, [page])

  let displayed = rows

  if (rowsPerPage > 0 && !updatePage) {
    displayed = rows?.slice(page * rowsPerPage, (page + 1) * rowsPerPage) || []
  }

  return (
    <>
      <TableContainer component={Paper} className={styles.card}>
        <div className={styles.toolbar}>
          <Button
            onClick={() => download(headers || [], rows || [])}
            color="primary"
            startIcon={<Download />}
            size="small"
            variant="text"
          >
            Exporter en CSV
          </Button>
        </div>
        {onSearch && (
          <TextField
            onChange={e => setSearch(e.target.value)}
            value={search}
            className={styles.searchBar}
            size="small"
            label="Rechercher"
          />
        )}
        <div className={styles.table}>
          <Table sx={{ minWidth: 650 }}>
            {headers?.length ? (
              <TableHead className={styles.header}>
                <TableRow>
                  {headers.map(cell =>
                    createCell(
                      cell,
                      orderBy && setOrderBy && cell?.hasOwnProperty('sort')
                        ? {
                            onSort: () =>
                              setOrderBy(
                                (cell as CustomCell).sort?.column || '',
                              ),
                            active:
                              orderBy.column ===
                              (cell as CustomCell).sort?.column,
                            direction: orderBy.direction,
                          }
                        : undefined,
                    ),
                  )}
                </TableRow>
              </TableHead>
            ) : null}

            <TableBody>
              {displayed?.map(row => (
                <TableRow
                  className={styles.row}
                  key={row.toString()}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {row.map(cell => createCell(cell))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[50]}
            component="div"
            count={total || rows?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, v) => setPage(v)}
            onRowsPerPageChange={e => {
              setRowsPerPage(+e.target.value)
              setPage(0)
            }}
          />
        </div>
      </TableContainer>
    </>
  )
}

export default MuiTable
