import { gql } from '@apollo/client'

export const USER_LIST = gql`
  query AdminUsers(
    $firstname: String
    $lastname: String
    $email: String
    $page: Int
    $token: String
  ) {
    AdminUsers(
      firstname: $firstname
      lastname: $lastname
      email: $email
      page: $page
      token: $token
    ) {
      count
      users {
        id
        firstname
        lastname
        registration
        token
        email
        premium
      }
    }
  }
`

export const USER = gql`
  query AdminUser($token: String) {
    AdminUsers(token: $token) {
      count
      users {
        id
        firstname
        lastname
        registration
        birthday
        address
        city
        photo
        zip
        country
        phone
        email
        token
        sheets {
          id
          title
          date
        }
        drafts {
          token
          title
          date
        }
      }
    }
  }
`

export const ADMIN_LIST = gql`
  query AdminList {
    AdminList {
      email
      id
    }
  }
`
