import {
  Mutation,
  SheetManagementType,
} from 'helpers/utils/__generated__/resolvers-types'
import React, { Component, useEffect, useState } from 'react'

import { Button } from '@mui/material'
import { SHEET_MANAGEMENT } from 'helpers/graphql/mutation/sheet.graphql'
import { put } from '../Service/Requests'
import { useMutation } from '@apollo/client'

type ValidateButtonProps = {
  isValid: boolean
  refetch: () => void
  id: number
}

const ValidateButton = (props: ValidateButtonProps) => {
  const { isValid, id, refetch } = props

  const [updateSheet, { data }] = useMutation<Mutation>(SHEET_MANAGEMENT)

  useEffect(() => {
    if (data?.SheetManagement) {
      refetch()
    }
  }, [data])

  return (
    <Button
      variant="contained"
      color={isValid ? 'error' : 'success'}
      onClick={() =>
        updateSheet({
          variables: {
            sheet: id,
            type: SheetManagementType.ToogleState,
          },
        })
      }
    >
      {isValid ? 'Désactiver' : 'Activer'}
    </Button>
  )
}

export default ValidateButton
