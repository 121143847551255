import React from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {IconButton, TextField} from "@mui/material";
import {HighlightOff} from "@mui/icons-material"
export default function Definition(props) {

    const onChange = (e) => {
        let def = props.value
        def[e.target.name] = e.target.value
        props.onChange(def)
    }

    return <>
        <div className="row mt-3">
            <div className="col-1 delete-button">
                <IconButton aria-label="delete" onClick={props.onDelete}>
                    <HighlightOff />
                </IconButton>
            </div>
            <div className={"col-11 col-md-3 px-1"}>
                <TextField placeholder="Mot à retenir" variant="outlined" className={"w-100"} value={props.value.title}
                           name={"title"} onChange={onChange}/>
            </div>
            <div className={"col-12 col-md-8 px-1 mt-1 mt-md-0"}>
                <TextareaAutosize placeholder="Définition" rows={5} className={"w-100"} value={props.value.content}
                                  name={"content"} onChange={onChange}/>
            </div>
        </div>

    </>
}