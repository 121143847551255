export type AuthReducer = {
  loggedIn: boolean
  token: null | string
  level: null | string
  lastLoggedIn?: Date
}

const initialState: AuthReducer = {
  loggedIn: false,
  token: null,
  level: null,
}

function auth(state = initialState, action: any): AuthReducer {
  switch (action.type) {
    case 'CONNECT':
      // Cookies.set('token', action.value['token'])
      return {
        token: action.value.token,
        level: action.value.level,
        loggedIn: true,
        lastLoggedIn: new Date(),
      }
    case 'DISCONNECT':
      // Cookies.delete('token')
      return {
        loggedIn: false,
        token: null,
        level: null,
        lastLoggedIn: undefined,
      }
    default:
      return state
  }
}

export default auth
