import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import MuiTable, { TableRow, customCell } from 'Component/MuiTable'
import React, { useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { DELETE_USER_MUTATION } from 'helpers/graphql/mutation/user.graphql'
import { Delete } from '@mui/icons-material'
import Modal from 'Component/Modal'
import Premium from 'Component/Icons/Premium'
import { Query } from 'helpers/utils/__generated__/resolvers-types'
import { USER_LIST } from 'helpers/graphql/query/user/index.graphql'
import UserModal from './UserModal'
import { format } from 'date-fns'
import styles from './style.module.scss'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

const Users = () => {
  const { token } = useParams()

  const [page, setPage] = useState(1)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [currentUser, setCurrentUser] = useState(token || null)
  const [toDelete, setToDelete] = useState("")

  const { data, refetch } = useQuery<Query>(USER_LIST, { variables: { page } })
  const [deleteUser, { data: dataDelete }] = useMutation(DELETE_USER_MUTATION)

  useEffect(() => {
    refetch({
      page,
      firstname: firstname || undefined,
      lastname: lastname || undefined,
      email: email || undefined,
    })
  }, [page, firstname, lastname, email, dataDelete])

  const headers: TableRow = [
    customCell('#Id'),
    customCell('Utilisateur'),
    customCell('Email', 'right'),
    customCell('Inscription', 'right'),
    '',
  ]

  const rows: TableRow[] =
    data?.AdminUsers.users.map(user => [
      user.id,
      customCell(
        <div className={styles.username}>
          {user.premium && <Premium />}
          {user.firstname} {user.lastname}
        </div>,
      ),
      customCell(user.email || 'N/A', 'right'),
      customCell(
        user.registration ? format(+user.registration, 'dd/MM/yyyy') : '',
        'right',
      ),
      customCell(
        <>
          <Tooltip title="Supprimer l'utilisateur ? " sx={{ mr: 1 }}>
            <Button
              variant="outlined"
              onClick={() => setToDelete(user.token)}
            >
              <Delete />
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCurrentUser(user.token)}
          >
            Voir l'utilisateur
          </Button>
        </>,
        'right',
      ),
    ]) || []

  return (
    <>
      <Typography variant="h1" align="center">
        Liste des utilisateurs
      </Typography>
      <UserModal user={currentUser} onClose={() => setCurrentUser(null)} />
      <div className={styles.searchBar}>
        <TextField
          value={firstname}
          onChange={e => setFirstname(e.target.value)}
          size="small"
          label={'Prénom'}
        />
        <TextField
          value={lastname}
          onChange={e => setLastname(e.target.value)}
          size="small"
          label={'Nom'}
        />
        <TextField
          label={'Email'}
          value={email}
          size="small"
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <Modal open={!!toDelete} onClose={() => setToDelete("")}>
        <div className={styles.modelDelete}>
          <Typography variant='h1'>Supprimer cet utilisateur ?</Typography>
          <Button variant='contained' startIcon={<Delete/>} onClick={() => deleteUser({ variables: { user: toDelete } })}>
            Supprimer
          </Button>
        </div>
      </Modal>
      <MuiTable
        rows={rows}
        headers={headers}
        updatePage={page => {
          setPage(page)
        }}
        total={data?.AdminUsers.count}
      />
    </>
  )
}
export default Users
