import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/store'

import { differenceInBusinessDays } from 'date-fns'

type Props = {
  children: React.ReactNode
}
const AuthGuard = (props: Props) => {
  const { children } = props

  const authReducer = useAppSelector(s => s.authReducer)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      !authReducer.lastLoggedIn ||
      differenceInBusinessDays(authReducer.lastLoggedIn, new Date()) > 1
    ) {
      dispatch({ type: 'DISCONNECT' })
    }
  }, [])

  return children
}

export default AuthGuard
