/* eslint-disable max-len */
import React from 'react'
import { SvgIcon } from '@mui/material'

type Props = {
  className?: string
}

const Premium = (props: Props) => {
  const { className } = props
  return (
    <SvgIcon className={className} viewBox="0 0 32 32">
      <g clipPath="url(#clip0_358_3078)">
        <path
          d="M0.955877 14.6461L1.57402e-06 15.0513C-0.000224722 15.1746 0.0239519 15.2968 0.0711384 15.4107C0.118325 15.5246 0.187589 15.6281 0.274939 15.7151L15.3404 30.7279C15.5162 30.9031 15.8182 30.9992 15.9955 31C16.1756 31.0008 16.4837 30.906 16.6626 30.7277L31.7254 15.7055C31.8126 15.6186 31.8817 15.5153 31.9288 15.4016C31.976 15.2879 32.0002 15.1661 32.0001 15.043L31.169 14.6461H21.1713L15.5631 16.4564L10.8201 14.6461L0.955877 14.6461Z"
          fill="#FFB739"
        />
        <path
          d="M31.9998 15.0431C31.9998 15.2846 31.9068 15.5244 31.7251 15.7054L16.6623 30.7276C16.4851 30.9048 16.1798 30.9997 16 30.9997V16.3152L21.1712 14.6461H31.1689L31.9998 15.0431Z"
          fill="#FF9441"
        />
        <path
          d="M10.8198 14.6461L15.9999 30.9997L21.171 14.6461H10.8198Z"
          fill="#FF9441"
        />
        <path d="M21.1711 14.6461L16 30.9997V14.6461H21.1711Z" fill="#FF7149" />
        <path
          d="M31.8429 14.5226L26.8288 7.00993C26.7597 6.90666 26.6707 6.81819 26.5671 6.74968L26.1126 6.96793L16.8674 7.02556L16 6.59337L15.299 7.03531L6.07469 7.09281L5.431 6.75062C5.32781 6.81911 5.23926 6.90742 5.1705 7.01043L0.156688 14.5326C0.0542807 14.6862 -0.000249605 14.8667 8.58946e-07 15.0513L32 15.0429C32.0004 14.8577 31.9457 14.6766 31.8429 14.5226Z"
          fill="#FFD17E"
        />
        <path
          d="M31.9997 15.0431C30.8374 15.0431 23.414 15.0456 16 15.0474V6.59337L16.8677 7.02531L26.1126 6.96787L26.567 6.74937C26.6705 6.8181 26.7594 6.90656 26.8286 7.00968L31.8431 14.5224C31.946 14.6765 32.0005 14.8578 31.9997 15.0431ZM5.94953 6.59337C5.76228 6.59337 5.58265 6.64968 5.43115 6.75049L10.9847 15.0484H10.9853L15.9997 6.59337H5.94953Z"
          fill="#FFB739"
        />
        <path
          d="M21.0166 15.0458L26.5669 6.74955C26.4137 6.64782 26.2339 6.59352 26.05 6.59343H15.9998L21.0126 15.0459H21.0166V15.0458Z"
          fill="#FF9441"
        />
      </g>
      <defs>
        <clipPath id="clip0_358_3078">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

Premium.defaultProps = {
  className: undefined,
}

export default Premium
