import { IconButton, Typography } from '@mui/material'
import MuiTable, { TableRow, customCell } from 'Component/MuiTable'

import { GET_BLOGS } from 'helpers/graphql/query/blog/index.graphql'
import { Launch } from '@mui/icons-material'
import { Query } from 'helpers/utils/__generated__/resolvers-types'
import React from 'react'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'

const Blogs = () => {
  const { data } = useQuery<Query>(GET_BLOGS)
  const navigate = useNavigate()

  const rows: TableRow[] =
    data?.Blogs.map(blog => [
      blog.id,
      blog.state ? 'Actif' : 'Inactif',
      blog.title,
      customCell(blog.writer, 'right'),
      customCell(format(+blog.date, 'dd/MM/yyyy'), 'right'),
      customCell(
        <IconButton onClick={() => navigate(`/blog/${blog.id}`)}>
          <Launch />
        </IconButton>,
        'right',
      ),
    ]) || []

  const headers: TableRow = [
    '#id',
    'État',
    'Titre',
    customCell('Auteur', 'right'),
    customCell('Date', 'right'),
    customCell('', 'right'),
  ]

  return (
    <>
      <Typography variant="h1" align="center">
        Blog
      </Typography>
      <MuiTable rows={rows} headers={headers} />
    </>
  )
}

export default Blogs
