import * as React from "react";

import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MuiModal from "@mui/material/Modal";
import clsx from "clsx";
import styles from "./style.module.scss";

type ModalProps = {
  header?: string | JSX.Element;
  footer?: string | JSX.Element;
  children?: React.ReactNode;
  open: boolean;
  size?: "small" | "medium" | "large" | "auto";
  onClose?: () => void;
  goBack?: () => void;
  className?: string;
};
export default function Modal(props: ModalProps) {
  const { header, footer, children, open, onClose, goBack, size, className } =
    props;
  return (
    <>
      <MuiModal open={open} onClose={onClose}>
        <div className={clsx(styles.modal, className)} aria-size={`${size}`}>
          <div>
            {goBack && (
              <IconButton className={styles.goBackBtn} onClick={goBack}>
                <ArrowBack />
              </IconButton>
            )}

            <div>
              {header && <div className={styles.header}>{header}</div>}
              <div className={styles.content}>{children}</div>
              {footer && <div className={styles.footer}>{footer}</div>}
            </div>
          </div>
        </div>
      </MuiModal>
    </>
  );
}

Modal.defaultProps = {
  header: undefined,
  footer: undefined,
  children: undefined,
  goBack: undefined,
  onClose: undefined,
};
