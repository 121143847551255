import { Button, IconButton, TextField } from '@mui/material'
import { Close, Settings } from '@mui/icons-material'
import { Mutation, Query } from 'helpers/utils/__generated__/resolvers-types'
import React, { useEffect, useRef, useState } from 'react'
import { checkIfB64, getBase64 } from 'helpers/functions/services'
import { useLazyQuery, useMutation } from '@apollo/client'

import { GET_BLOGS } from 'helpers/graphql/query/blog/index.graphql'
import QuillEditor from '../../Component/QuillEditor'
import { UPSERT_BLOG } from 'helpers/graphql/mutation/blog.graphql'
import { useParams } from 'react-router-dom'

const Editor = () => {
  const { id } = useParams()

  const [value, setValue] = useState('')
  const [title, setTitle] = useState('')
  const [header, setHeader] = useState('')
  const [summary, setSummary] = useState('')
  const [state, setState] = useState(0)

  const [fetch, { data }] = useLazyQuery<Query>(GET_BLOGS)
  const [upsert, { data: dataUpsert }] = useMutation<Mutation>(UPSERT_BLOG)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const menu = useRef<HTMLDivElement>(null)

  const uploadImage = async e => {
    const file = e.target.files[0]

    if (file.name && RegExp(/.+\.(jpg|png|jpeg)$/gi).test(file.name)) {
      setHeader(await getBase64(file))
    }
  }

  useEffect(() => {
    if (data?.Blogs.length) {
      setValue(data.Blogs[0].value)
      setTitle(data.Blogs[0].title)
      setHeader(data.Blogs[0].header)
      setSummary(data.Blogs[0].summary)
      setState(+data.Blogs[0].state)
    }
  }, [data])

  useEffect(() => {
    if (id) {
      fetch({
        variables: {
          id: +id,
        },
      })
    }
  }, [id])

  return (
    <>
      <div className="d-md-none d-flex justify-content-end">
        <IconButton
          onClick={() => {
            menu.current?.classList.toggle('show')
          }}
        >
          <Settings />
        </IconButton>
      </div>
      <div className="row">
        <div className="col-md-9 col-12 px-5 pt-2 ">
          <div className="editor mx-auto">
            <TextField
              variant="standard"
              value={title}
              placeholder={"Titre de l'article"}
              onChange={e => setTitle(e.target.value)}
              className="w-100 mb-3"
            />

            <QuillEditor value={value} onChange={html => setValue(html)} />

            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (id) {
                    upsert({
                      variables: {
                        id: +id,
                        title,
                        value,
                        summary,
                        header,
                        state,
                      },
                    })
                  }
                }}
                className="mt-5"
              >
                Enregister
              </Button>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-12 side-menu-blog pt-2" ref={menu}>
          <div className="d-md-none mb-4">
            <Button
              variant="outlined"
              endIcon={<Close />}
              onClick={() => {
                menu.current?.classList.toggle('show')
              }}
            >
              Fermer
            </Button>
          </div>
          <div>
            <p className="title">Image d'entête</p>
            <>
              {header ? (
                <img
                  src={
                    checkIfB64(header)
                      ? header
                      : process.env.REACT_APP_S3 + header
                  }
                  className="w-100"
                />
              ) : null}
            </>
            <input
              type="file"
              accept="image/*"
              ref={hiddenFileInput}
              onChange={uploadImage}
              style={{ display: 'none' }}
            />
            <div className="my-3 text-center">
              <Button
                variant="contained"
                color="primary"
                className="ms-3"
                size="small"
                onClick={() => hiddenFileInput.current?.click()}
              >
                Image d'entête
              </Button>
            </div>
            <hr className="my-4" />
            <TextField
              variant="standard"
              value={summary}
              label="Résumé"
              onChange={e => setSummary(e.target.value)}
              multiline
              maxRows={4}
              className="w-100 mb-3"
            />
            <div className="text-center">
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                // onClick={this.publish}
              >
                {state === 0 || !state
                  ? 'Publier'
                  : 'Enregistrer en tant que brouillons'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Editor
