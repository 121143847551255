import React, {Component, createRef, useCallback, useEffect, useState} from "react";
import Quill from "quill"
import "quill/dist/quill.snow.css"
import IconButton from "@mui/material/IconButton";
import {HighlightOff} from "@mui/icons-material";


export default function Editor(props) {
    const [quill, setQuill] = useState()
    let toolbar_options = []

    useEffect(() => {
        if (quill == null) return
        if(quill.root.innerHTML === "<p><br></p>"){
            quill.root.innerHTML = props.value
        }
    }, [props, quill])

    useEffect(() => {
        if (quill == null) return

        const handler = (delta, oldDelta, source) => {
            props.onChange(quill.root.innerHTML)
        }
        quill.on("text-change", handler)

        return () => {
            quill.off("text-change", handler)
        }
    }, [quill])

    if (props.premium) {
        toolbar_options = [
            [{list: "ordered"}, {list: "bullet"}],
            ["bold", "italic", "underline"],
            [{color: []}, {background: []}],
            [{script: "sub"}, {script: "super"}],
            [{align: []}],
            ["image", "blockquote", "code-block"],
            ["clean"],
        ]
    } else {
        toolbar_options = [
            ["bold", "italic", "underline"],
        ]
    }

    const wrapperRef = useCallback(wrapper => {
        if (typeof document === "undefined") return;
        if (wrapper == null) return

        wrapper.innerHTML = ""
        const editor = document.createElement("div")
        wrapper.append(editor)
        const q = new Quill(editor, {
            theme: "snow",
            placeholder: 'Votre texte explicatif ici',
            modules: {toolbar: toolbar_options},
        })
        setQuill(q)
    }, [])

    return <>
        <div className="row mt-3">
            {
                props.premium
                ?<div className="col-1 delete-button">
                        <IconButton aria-label="delete" onClick={props.onDelete}>
                            <HighlightOff />
                        </IconButton>
                    </div>
                    : <></>
            }

            <div className={props.premium ? "col-11" : "col-12"} ref={wrapperRef}/>
        </div>
    </>

}