import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation LoginAdmin($username: String!, $password: String!) {
    LoginAdmin(username: $username, password: $password) {
      level
      token
    }
  }
`
