import { gql } from "@apollo/client";

export const GET_BLOGS = gql`
  query Blogs($limit: Int, $id: Int) {
    Blogs(limit: $limit, id: $id) {
      value
      date
      title
      writer
      state
      summary
      id
      header
    }
  }
`

