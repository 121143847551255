import MuiTable, { TableRow, customCell } from 'Component/MuiTable'

import React from 'react'
import { TEMPLATES } from 'helpers/graphql/query/dashboard.graphql'
import { useQuery } from '@apollo/client'

const Templates = () => {
  const { data } = useQuery(TEMPLATES)

  const header: TableRow = [
    customCell('Template'),
    customCell('Utilisation', 'right'),
    customCell('%', 'right'),
  ]

  const total = data?.AdminTemplates?.reduce((acc, t) => acc + t.count, 0)

  const rows: TableRow[] = data?.AdminTemplates?.map(t => [
    t.template,
    customCell(t.count, 'right'),
    customCell(((t.count / total) * 100).toFixed(2), 'right'),
  ])

  return (
    <>
      <MuiTable headers={header} rows={rows} />
    </>
  )
}

export default Templates
