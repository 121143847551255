import './styles/global.scss'
import './styles/sheet.scss'
import './styles/website.css'
import './index.scss'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import { persistor, store } from './store/store'

import App from './App'
import AuthGuard from './Component/authGuard'
import Menu from './Component/Menu'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { frFR } from '@mui/material/locale'

const container = document.getElementById('root')
const root = createRoot(container)

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#fe491a',
      },
      secondary: {
        main: '#fa63aa',
      },
    },
    typography: {
      h1: {
        fontSize: '1.875rem',
        fontWeight: 700,
        fontFamily: "'Raleway', sans-serif",
      },
      h2: {
        fontSize: '1.563rem',
        fontWeight: 700,
        fontFamily: "'Raleway', sans-serif",
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: 500,
        fontFamily: "'Raleway', sans-serif",
      },
      h4: {
        fontSize: '1.4rem',
        fontWeight: 500,
        fontFamily: "'Raleway', sans-serif",
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 400,
        fontFamily: "'Raleway', sans-serif",
      },
      h6: {
        fontSize: '1.1rem',
        fontWeight: 400,
        fontFamily: "'Raleway', sans-serif",
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 300,
        fontFamily: "'Open Sans', sans-serif",
      },
    },
    zIndex: {
      appBar: 1000,
    },
  },
  frFR,
)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AuthGuard>
            <Menu />
            <div style={{ marginTop: '66px' }}>
              <App />
            </div>
          </AuthGuard>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
