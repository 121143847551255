import React, { useEffect, useRef } from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core'

import clsx from 'clsx'
import parse from 'html-react-parser'

type VisualizeHtmllProps = {
  type: number
  file?: string
  html?: string
  template?: string
  preview?: boolean | undefined
  forPDF?: boolean
}

const VisualizeHtml = (props: VisualizeHtmllProps) => {
  const { type, html, file, preview, forPDF } = props

  const container = useRef<HTMLDivElement>(null)
  const sheet = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!forPDF && typeof window !== 'undefined') {
      if (container.current) {
        const toScale = Math.min(
          container.current!.parentElement!.clientWidth / 875.6,
          container.current!.parentElement!.clientHeight / 1234.2,
        )
        sheet.current!.style.transform = `scale(${
          toScale > 1 ? 1 : toScale
        }) translate(-50%, -50%)`
      }

      window.addEventListener('resize', () => {
        if (container.current && sheet.current) {
          const toScale = Math.min(
            container.current!.parentElement!.clientWidth / 875.6,
            container.current!.parentElement!.clientHeight / 1234.2,
          )

          sheet.current!.style.transform = `scale(${
            toScale > 1 ? 1 : toScale
          }) translate(-50%, -50%)`
        }
      })
    }
  }, [container, forPDF])

  const oldSheet = html?.substring(12, 18) === 'header'

  const content = (
    <>
      {preview && <div className="sheet-preview" />}
      {type === 1
        ? file && (
            <div id="pdf-container" className="without-scroll">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist/build/pdf.worker.min.js">
                <Viewer fileUrl={file} />
              </Worker>
            </div>
          )
        : parse(
            html?.replaceAll(
              'https://api.partielo.fr/',
              'https://api-v2.partielo.com/',
            ) ?? '',
          )}
    </>
  )

  return (
    <div id={forPDF ? 'forpdf' : 'visualizer'} ref={container}>
      <div ref={sheet}>
        <div className={'sheet-container'}>
          {oldSheet ? (
            <div className="templateClassic">{content}</div>
          ) : (
            content
          )}
        </div>
      </div>
    </div>
  )
}

export default VisualizeHtml
