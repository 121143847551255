import axios from 'axios'
import {store} from "../store/store"
import Cookies from "js-cookie";

const server = process.env.REACT_APP_SERVER

const disconnect = () => {
    const action = {type: "DISCONNECT", value: {}}
    store.dispatch(action)
}


export const post = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        const user = store.getState()['authReducer']
        const isLogged = user['loggedIn'] === true


        let config = {
            method: 'post',
            url: server + url,
            headers: {
                'Content-Type': 'application/json',
                'authorization': isLogged ? user['token'] : null
            },
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response)
            })
            .catch((e) => {
                console.log(e)
                if (e.response && e.response.data.error === "Unauthorized: token expired") {
                    disconnect()
                }
                if (e.response) {
                    reject(e.response.data)
                }
                reject()
            })
    })
};

export const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        let user = {}
        try {
            user = store.getState()['authReducer']
        } catch (e) {
        }
        const isLogged = user['loggedIn'] === true
        if (isLogged) {
            params["token"] = user['user']
        }
        let config = {
            method: 'get',
            url: server + url,
            headers: {
                'authorization': isLogged ? user['token'] : null
            },
            params: params
        };

        axios(config)
            .then((response) => {
                resolve(response)
            })
            .catch((e) => {
                if (e.response && e.response.data.error === "Unauthorized: token expired") {
                    disconnect()
                }
                if (e.response) {
                    reject(e.response.data)
                }
                reject(e)
            })
    })
};

export const del = (url, data) => {
    return new Promise((resolve, reject) => {
        const user = store.getState()['authReducer']
        const isLogged = user['loggedIn'] === true
        let config = {
            method: 'delete',
            url: server + url,
            headers: {
                'Content-Type': 'application/json',
                'authorization': isLogged ? user['token'] : null
            },
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response)
            })
            .catch((e) => {
                if (e.response && e.response.data.error === "Unauthorized: token expired") {
                    disconnect()
                }
                if (e.response) {
                    reject(e.response.data)
                }
                reject()
            })
    })
};

export const put = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        const user = store.getState()['authReducer']
        const isLogged = user['loggedIn'] === true
        let config = {
            method: 'put',
            url: server + url,
            headers: {
                'Content-Type': 'application/json',
                'authorization': isLogged ? user['token'] : null
            },
            data: data
        };

        axios(config)
            .then((response) => {
                resolve(response)
            })
            .catch((e) => {
                if (e.response && e.response.data.error === "Unauthorized: token expired") {
                    disconnect()
                }
                if (e.response) {
                    reject(e.response.data)
                }
                reject()
            })
    })
};

export const getFile = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        let user = {}
        try {
            user = store.getState()['authReducer']
        } catch (e) {
        }
        const isLogged = user['loggedIn'] === true

        let config = {
            method: 'get',
            url: server + url,
            headers: {
                'authorization': isLogged ? user['token'] : null
            },
            params: params,
            responseType: 'blob',
        };

        axios(config)
            .then(({data}) => {
                resolve(new Blob([data]))
            })
            .catch((e) => {
                if (e.response && (e.response.data.error === "Unauthorized: token expired" || e.response.data.error === "Unauthorized: token expired")) {
                    disconnect()
                }
                if (e.response) {
                    reject(e.response.data)
                }
                reject(e)
            })
    })
}